import { Component, Input, OnInit } from '@angular/core';
import {
  DISPLAYTEXT_QUOTESUBMISSION_WARNING,
  DISPLAYTEXT_WARNING_LONDONREFERRAL,
  DISPLAYTEXT_QUOTEFLOWSTATUS_APPROVED,
  DISPLAYTEXT_QUOTEFLOWSTATUS_PENDINGINFO,
  DISPLAYTEXT_QUOTEFLOWSTATUS_PREQUOTE,
  DISPLAYTEXT_QUOTEFLOWSTATUS_TOREVIEW,
} from 'src/app/constants/quote-constant';
import { QuoteFlowStatus } from 'src/app/constants/quoteStatus';
import { TooltipV2Model } from 'src/app/models/tooltip.V2.model ';

@Component({
  selector: 'app-status-badge-v2',
  templateUrl: './status-badge-v2.component.html',
  styleUrls: ['./status-badge-v2.component.less'],
})
export class StatusBadgeV2Component implements OnInit {
  @Input() rowData: any;
  @Input() columnName: string = '';

  status: string = '';
  statusHavingIcon: string[] = [
    QuoteFlowStatus.toReview,
    QuoteFlowStatus.pendingInfo,
    QuoteFlowStatus.warning,
    QuoteFlowStatus.londonReferral,
  ];

  badgeStatusStyle_orange: string[] = [
    QuoteFlowStatus.warning,
    QuoteFlowStatus.londonReferral,
  ];
  quoteFlowStatusList: string[] = [
    QuoteFlowStatus.preQuote,
    QuoteFlowStatus.approved,
    QuoteFlowStatus.toReview,
    QuoteFlowStatus.pendingInfo,
    QuoteFlowStatus.warning,
    QuoteFlowStatus.londonReferral,
  ];
  badgeStatusStyle_gold: string[] = [
    QuoteFlowStatus.toReview,
    QuoteFlowStatus.pendingInfo,
  ];
  badgeStatusStyle_purple: string[] = [
    QuoteFlowStatus.preQuote,
    QuoteFlowStatus.approved,
  ];

  curvedStyledBadges: string[] = [
    QuoteFlowStatus.warning,
    QuoteFlowStatus.londonReferral,
  ];

  constructor() {}

  ngOnInit(): void {
    this.status = this.getStatusText();
  }

  getIcon(): string {
    let iconPath: string = `assets/img/`;
    switch (this.status) {
      case QuoteFlowStatus.toReview:
      case QuoteFlowStatus.pendingInfo:
        return `${iconPath}annotation-dots.svg`;
      case QuoteFlowStatus.warning:
      case QuoteFlowStatus.londonReferral:
        return `${iconPath}help-circle-orange.svg`;
      default:
        return `${iconPath}info-circle.svg`;
    }
  }

  isStatusHavingIcon(): boolean {
    return this.statusHavingIcon.includes(this.status);
  }

  isCurvedBadge(): boolean {
    return this.curvedStyledBadges.includes(this.status);
  }

  getBadgeStyle(): string {
    return this.getBadgeStyleClass(this.status);
  }

  getStatusText(): string {
    let statusText = '';
    if (this.columnName === 'Status') {
      statusText = this.rowData?.Status;
    } else if (this.columnName === 'Submission Date') {
      statusText = this.rowData?.['Submission Date'].status
        ? this.rowData['Submission Date'].status
        : '';
    }
    return statusText;
  }

  getStatusDisplayText(): string {
    if (this.status === QuoteFlowStatus.warning) {
      return DISPLAYTEXT_QUOTESUBMISSION_WARNING;
    } else if (this.status === QuoteFlowStatus.londonReferral) {
      return DISPLAYTEXT_WARNING_LONDONREFERRAL;
    } else if (this.status === QuoteFlowStatus.toReview) {
      return DISPLAYTEXT_QUOTEFLOWSTATUS_TOREVIEW;
    } else if (this.status === QuoteFlowStatus.approved) {
      return DISPLAYTEXT_QUOTEFLOWSTATUS_APPROVED;
    } else if (this.status === QuoteFlowStatus.preQuote) {
      return DISPLAYTEXT_QUOTEFLOWSTATUS_PREQUOTE;
    } else if (this.status === QuoteFlowStatus.pendingInfo) {
      return DISPLAYTEXT_QUOTEFLOWSTATUS_PENDINGINFO;
    } else return this.status;
  }

  getBadgeStyleClass(statusText: string = ''): string {
    let badgeStyleClass: string = 'badge-default';
    if (this.badgeStatusStyle_orange.includes(statusText)) {
      badgeStyleClass = 'badge-orange';
    } else if (this.badgeStatusStyle_gold.includes(statusText)) {
      badgeStyleClass = 'badge-gold';
    } else if (this.badgeStatusStyle_purple.includes(statusText)) {
      badgeStyleClass = 'badge-purple';
    }

    badgeStyleClass = this.isCurvedBadge()
      ? `${badgeStyleClass} curved-badge`
      : `${badgeStyleClass} rounded-badge`;

    // to handle existing quote status
    if (statusText && !this.quoteFlowStatusList.includes(statusText)) {
      let badgeStyleClass_OldStatus = this.getStatusClass(statusText);
      badgeStyleClass = `status-badge ${badgeStyleClass_OldStatus}`;
    }

    return badgeStyleClass;
  }

  getStatusClass(status: string): string {
    if (status === undefined || status === '') {
      return '';
    }
    let infoArray = ['info', 'endrs-saved', 'not taken up'];
    let successArray = [
      'active',
      'success',
      'new submissions',
      'submission', // to be removed - will be replaced with quote flow status
      'reinstated',
      'bound',
      'renewed',
      'renewal quote',
      'renewal app',
      'renewalapp',
      'renewalquoted',
      'quote',
      'quoted',
      'endorsed',
    ];
    let dangerArray = [
      'inactive',
      'quote closed',
      'quote-closed',
      'cancelled',
      'declined',
      'closed',
      'expired',
      'lapsed',
    ];
    let warningArray = ['referral', 'referred'];
    let warnImageArray = ['submission warn']; // to be removed - will be replaced with quote flow status
    let warningStyle2Array = ['indicated'];

    if (warningArray.includes(status.toLowerCase())) {
      return 'warning-badge';
    }
    if (warningStyle2Array.includes(status.toLowerCase())) {
      return 'warning-badge-style-2';
    }
    if (infoArray.includes(status.toLowerCase())) {
      return 'info-badge';
    }
    if (successArray.includes(status.toLowerCase())) {
      return 'success-badge';
    }
    if (dangerArray.includes(status.toLowerCase())) {
      return 'danger-badge';
    }
    if (warnImageArray.includes(status.toLowerCase())) {
      return 'warning-image-badge';
    }
    return 'success-badge';
  }

  setupTooltip(rowData, columnName): TooltipV2Model {
    const defaultResult = {
      type: '',
      hiddenHead: false,
      hiddenHeadTxt: true,
      tooltipHeaderTxt: '',
      tooltipContentTxt: '',
      tooltipContentList: [],
    };
    let switchText = '';
    if (columnName === 'Status') {
      switchText = rowData?.Status ? rowData.Status : '';
    } else if (columnName === 'Submission Date') {
      switchText = rowData?.['Submission Date'].status
        ? rowData['Submission Date'].status
        : '';
    }
    switch (switchText) {
      case QuoteFlowStatus.toReview:
        return {
          type: '',
          hiddenHead: false,
          hiddenHeadTxt: false,
          tooltipHeaderTxt: 'Review request by:',
          tooltipContentTxt: '@' + rowData?.toReviewReferredBy || '',
          tooltipContentList: [],
        };
      case QuoteFlowStatus.warning:
        return {
          type: 'list',
          hiddenHead: false,
          hiddenHeadTxt: true,
          tooltipHeaderTxt: '',
          tooltipContentTxt: '',
          tooltipContentList: rowData?.['Submission Date']?.warningArray || [],
        };
      case QuoteFlowStatus.pendingInfo:
        return {
          type: 'list',
          hiddenHead: false,
          hiddenHeadTxt: false,
          tooltipHeaderTxt: 'Pending info:',
          tooltipContentTxt: '',
          tooltipContentList: rowData?.pendingInfoReason || [], // there will be one pending info reason
        };
      case QuoteFlowStatus.londonReferral:
        // TO BE DEFINED REQUIREMENT
        // Referral not within authority
        return {
          type: 'list',
          hiddenHead: false,
          hiddenHeadTxt: false,
          tooltipHeaderTxt: 'Referral not within authority',
          tooltipContentTxt: '',
          tooltipContentList:
            rowData?.['Submission Date']?.londonReferralArray || [], // ['Reason 1', 'Reason 2'],
        };
      // else
      // return {
      //   type: 'list',
      //   hiddenHead: false,
      //   hiddenHeadTxt: true,
      //   tooltipHeaderTxt: '',
      //   tooltipContentTxt: '',
      //   tooltipContentList: ['Revenue over $50m'],
      // }

      default:
        return defaultResult;
    }
  }
}
