import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { StatusMapping } from 'src/app/constants/policyStatus';
import { SUBDOMAINCODE_QUOTEFLOWSTATUS_PENDINGINFO } from 'src/app/constants/quote-constant';
import { QuoteFlowStatus } from 'src/app/constants/quoteStatus';
import { Pagination } from 'src/app/entities/boxx-response';
import { LocalStorageService } from 'src/app/services/localstorage-service';
import { PolicyDashboardService } from 'src/app/services/policy-dashboard.service';
import { SharedFunctionService } from 'src/app/services/shared/shared-function.service';
import { getDashboardSelector } from 'src/app/store/dashboard/dashboard.selector';
import { getDateString, getFormattedDateTime } from 'src/app/utils/formatDate';
import { getErrorMessage } from 'src/app/utils/utils';

interface FilterTab {
  id: number;
  name: string;
  value: string;
  active: boolean;
  count: number;
  hasUnread: boolean;
}

interface taskTableHeadSortKeys {
  'Applicant name': string;
  Status: string;
  Source: string;
  'Submission Date': string;
  'Need by': string;
  Industry: string;
  Revenue: string;
  Broker: string;
  Brokerage: string;
}

@Component({
  selector: 'app-dashboard-home-v2',
  templateUrl: './dashboard-home-v2.component.html',
  styleUrls: ['./dashboard-home-v2.component.less'],
})
export class DashboardHomeV2Component implements OnInit {
  filterTabs: FilterTab[] = [
    {
      id: 1,
      name: 'New Business',
      value: 'Submission',
      active: true,
      count: 5,
      hasUnread: false,
    },
    {
      id: 2,
      name: 'Referred Tasks',
      value: 'Referred',
      active: false,
      count: 0,
      hasUnread: false,
    },
    {
      id: 3,
      name: 'Renewals',
      value: 'renewals',
      active: false,
      count: 0,
      hasUnread: false,
    },
    {
      id: 4,
      name: 'Quoted',
      value: 'quoted',
      active: false,
      count: 0,
      hasUnread: false,
    },
  ];
  taskTableData: { [key: string]: any }[] = [];
  taskTableHeaders: string[] = [
    'Applicant name',
    'Status',
    'Source',
    'Submission Date',
    'Need by',
    'Industry',
    'Revenue',
    'Broker',
    'Brokerage',
  ];
  taskTableHeadSortKeys: taskTableHeadSortKeys = {
    'Applicant name':
      'policy.insured.companyName,policy.insured.firstName,policy.insured.lastName',
    Status: 'quoteFlowStatus',
    Source: 'apiCreated',
    'Submission Date': 'policy.createdDt',
    'Need by': 'policy.needByDt',
    Industry: 'policy.insured.industry.name',
    Revenue: 'policy.insured.revenue',
    Broker:
      'policy.brokerageProducerBOR.firstName,policy.brokerageProducerBOR.lastName,policy.brokerageBranchBOR.name',
    Brokerage: 'policy.brokerageBOR.name',
  };
  tableColumnsExcluded: string[] = [
    'isUnread',
    'toReviewReferredBy',
    'pendingInfoReason',
    'Quote ID',
  ];
  taskTableHeadersCopy: string[] = [
    'Applicant name',
    'Status',
    'Source',
    'Submission Date',
    'Need by',
    'Industry',
    'Revenue',
    'Broker',
    'Brokerage',
  ];
  followupTableData: { [key: string]: any }[] = [];
  followupTableHeaders: string[] = [];
  followupTableHeadersCopy: string[] = [];

  newTableData: { [key: string]: any }[] = [];
  totalDataCount = 0;
  taskPagination: Pagination;
  followupPagination: Pagination;
  showTaskTblSpinner: boolean = true;
  showFollowupTblSpinner: boolean = true;
  isInsuredTypeCompany: boolean = true;
  currentScreen: string = '';
  permissionList: { [x: string]: boolean } = {};
  shortDateFormat: string = '';
  shortDateFormat_2: string = '';
  longDateFormat: string = '';
  isRestrictedRoleSubmission: boolean = false;
  boxxUserId: string = '';
  currentFilterTabIndex: number = 0;
  searchQuery: string = '';
  filterForm: FormGroup;
  isSearchable: boolean = false;
  quoteFlowUrl: string = '';
  unFilteredQuotesData = [];
  quoteFlowSteps: string = '';

  constructor(
    private policyDashboardService: PolicyDashboardService,
    private store: Store,
    private fb: FormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private localStorageService: LocalStorageService,
    private sharedFunctionService: SharedFunctionService,
  ) {
    this.filterForm = this.fb.group({
      effectiveDt: this.fb.group({
        from: [''],
        to: [''],
      }),
      createdDt: this.fb.group({
        from: [''],
        to: [''],
      }),
      brokerageBranchProd: [[]],
      underwriter: [[]],
      status: [[]],
      risks: [[]],
      insuredName: [''],
      premiumRange: this.fb.group({
        from: [''],
        to: [''],
      }),
    });
  }

  ngOnInit(): void {
    if (this.activatedRoute.snapshot.queryParams['mock'] === 'false') {
      this.localStorageService.setMockExternalAPIs('false');
    } else {
      this.localStorageService.setMockExternalAPIs('true');
    }
    this.store.pipe(select(getDashboardSelector)).subscribe((data) => {
      this.currentScreen = data.currentScreenDescription;
      this.permissionList = data.litePermissionList;
      this.shortDateFormat = data.shortDateFormat;
      this.shortDateFormat_2 = data.shortDateFormat_2;
      this.longDateFormat = data.longDateFormat;
      this.isRestrictedRoleSubmission = data.isRestrictedRoleSubmission;
    });
    this.boxxUserId = localStorage.getItem('bid');
    this.quoteFlowSteps = this.localStorageService.getQuoteFlowSteps();
    if (this.quoteFlowSteps === '7') {
      this.quoteFlowUrl = 'quote';
    } else if (this.quoteFlowSteps === '4') {
      this.quoteFlowUrl = 'quote4flow';
    } else {
      this.quoteFlowUrl = 'workflow3';
    }

    this.getTableData({
      tableType: 'task',
      pageNumber: 1,
      limit: 10,
      sort: '',
      scrollDirection: 'scrollingDown',
    });
  }

  async loadResults(filterParams: any, appendData: boolean = false) {
    let { tableType, pageNumber, limit, sort, scrollDirection, insuredName } =
      filterParams;
    if (this.searchQuery === '') {
      this.isSearchable = false;
    }

    const sortWithDefaultSort =
      sort !== '' ? `isRead: asc,${sort}` : `isRead: asc`;
    tableType == 'task'
      ? (this.showTaskTblSpinner = true)
      : (this.showFollowupTblSpinner = true);

    // const quoteStatus =
    //   StatusMapping[
    //     this.filterTabs[this.currentFilterTabIndex].value.toLowerCase()
    //   ];

    let insuredType = this.getInsuredType();

    let filter = this.makeFilter();

    // In BE added logic so no need to send the quote status from dashboard.
    // quoteStatus && (filter['quoteStatus'] = quoteStatus);

    filter['insuredName'] && delete filter['insuredName'];
    insuredName && (filter['insuredName'] = this.searchQuery);
    // if (quoteStatus) {
    //   if (filter.quoteStatus) {
    //     filter.quoteStatus += ',' + quoteStatus;
    //   } else {
    //     filter = Object.assign(filter, { quoteStatus: quoteStatus });
    //   }
    // }
    // if (filter.trxDays) {
    //   if (filter.quoteStatus) {
    //     let temp = filter.quoteStatus
    //       .split(',')
    //       .findIndex((x) => x == StatusMapping['quoted']);
    //     if (temp == -1) {
    //       filter.quoteStatus += ',' + StatusMapping['quoted'];
    //     }
    //   } else {
    //     filter = Object.assign(filter, { quoteStatus: StatusMapping['quoted'] });
    //   }
    // }

    this.policyDashboardService
      .GetAllQuoteByFilter(
        insuredType,
        filter,
        pageNumber,
        limit,
        sortWithDefaultSort,
      )
      .subscribe({
        next: (data) => {
          // to get policyRiskTrxId & status of quotes
          if (tableType === 'task' && appendData) {
            this.unFilteredQuotesData = [
              ...this.unFilteredQuotesData,
              ...data.data,
            ];
          } else if (tableType === 'task' && !appendData) {
            this.unFilteredQuotesData = data.data;
          }

          const tableData: { [key: string]: any }[] = data.data?.map(
            (dataObj, index: number) => {
              if (!dataObj.policyPeriod.isRead) {
                this.filterTabs[this.currentFilterTabIndex].hasUnread = true;
              }
              const status = !dataObj.policyRiskTrxes?.quoteProcessStatus
                ? StatusMapping[
                    dataObj.policyRiskTrxes?.quoteStatus?.description.toLowerCase()
                  ]
                : dataObj.policyRiskTrxes?.quoteProcessStatus?.subdomaincode;
              let toReviewReferredBy = '';
              let pendingInfoReason = [];
              let warningArray = [];
              let londonReferralArray = [];
              if (status === QuoteFlowStatus.toReview) {
                toReviewReferredBy = this.getReferredBy(dataObj);
              } else if (status === QuoteFlowStatus.pendingInfo) {
                pendingInfoReason = this.getPendingInfoResons(dataObj);
              }
              warningArray = this.getWarningArray(dataObj);
              let tableRowData = {
                'Quote ID': dataObj.policy.pkgPolicyNumber,
                'Applicant name':
                  dataObj.policy.insured.InsuredType.subdomaincode ===
                  'ENTITYTYPE_COMPANY'
                    ? dataObj.policy.insured.companyName
                    : `${dataObj.policy.insured.firstName} ${dataObj.policy.insured.lastName}`,
                Status: status,
                Source: dataObj.policyPeriod.apiCreated ? 'API' : 'BOXX',
                'Submission Date': {
                  date: getFormattedDateTime(
                    dataObj?.policy?.createdDt,
                    this.shortDateFormat_2,
                  ),
                  status:
                    warningArray.length > 0
                      ? QuoteFlowStatus.warning
                      : londonReferralArray.length > 0
                      ? QuoteFlowStatus.londonReferral
                      : '',
                  warningArray: warningArray,
                  londonReferralArray: londonReferralArray,
                },
                'Need by': !!dataObj.policy.needByDt
                  ? getFormattedDateTime(
                      dataObj.policy.needByDt,
                      this.shortDateFormat_2,
                    )
                  : !dataObj.policyPeriod.apiCreated
                  ? 'TBD'
                  : '-',
                Industry: dataObj.policy.insured.industry.name ?? '',
                Revenue: dataObj.policy.insured.revenue,
                Broker: {
                  producer: `${dataObj.policy.brokerageProducerBOR.firstName} ${dataObj.policy.brokerageProducerBOR.lastName}`,
                  teamName: '-',
                  branch: dataObj.policy.brokerageBranchBOR.name,
                },
                Brokerage: dataObj.policy.brokerageBOR.name,
                isUnread: !dataObj.policyPeriod.isRead,
                toReviewReferredBy: toReviewReferredBy,
                pendingInfoReason: pendingInfoReason,
              };

              return tableRowData;
            },
          );

          if (pageNumber === 1) {
            this.filterTabs[this.currentFilterTabIndex].hasUnread =
              tableData.some((item) => item['isUnread']);
          }
          this.newTableData = tableData;

          tableType === 'task'
            ? (this.taskPagination = data.pagination)
            : (this.followupPagination = data.pagination);
          this.handleDataChange(scrollDirection, tableType, appendData);
        },
        error: (error) => {
          tableType === 'task'
            ? (this.showTaskTblSpinner = false)
            : (this.showFollowupTblSpinner = false);
          this.sharedFunctionService.triggerEvent({
            type: 'error',
            headerText: 'error',
            bodyText: getErrorMessage(error),
          });
        },
      });
  }

  getInsuredType() {
    let insuredType = 1;
    if (this.isInsuredTypeCompany) {
      insuredType = 2;
    }
    return insuredType;
  }

  makeFilter() {
    return {
      underwriterId: this.boxxUserId,
      isDashboard: true,
    };
    // let insuredName =
    //   this.filterSelectedOptions?.insuredName ?? this.searchQuery;
    // const brokerVal = this.filterSelectedOptions?.brokerageBranchProd ?? [];
    // const brokerIds = brokerVal
    //   .filter((item) => item.type === 'Brokerage')
    //   .map((currentValue) => currentValue.id)
    //   .join(',');
    // const branchIds = brokerVal
    //   .filter((item) => item.type === 'Branch')
    //   .map((currentValue) => currentValue.id)
    //   .join(',');
    // const producersIds = brokerVal
    //   .filter((item) => item.type === 'Producer')
    //   .map((currentValue) => currentValue.id)
    //   .join(',');
    // const productsVal = this.filterSelectedOptions?.risks ?? [];
    // const productIds = productsVal.map((p) => p.id).join(',');
    // const underwritersVal = this.filterSelectedOptions?.underwriter ?? [];
    // const underwriterIds = underwritersVal
    //   .map((currentValue) => currentValue.id)
    //   .join(',');
    // const statusVal = this.filterSelectedOptions?.status
    //   .map((f) => StatusMapping[f.value.toLowerCase()])
    //   .join(',');
    // const createDt = this.filterSelectedOptions?.createdDt;
    // const effectiveDt = this.filterSelectedOptions?.effectiveDt;
    // const premium = this.filterSelectedOptions?.premiumRange;
    // let createRange = '';
    // let effectiveRange = '';
    // let premiumRange = '';
    // if (createDt && createDt?.from && createDt?.to) {
    //   const formattedFromDate = getDateString(
    //     createDt.from,
    //     this.shortDateFormat,
    //   );
    //   const formattedToDate = getDateString(createDt.to, this.shortDateFormat);
    //   createRange = `${formattedFromDate},${formattedToDate}`;
    // }
    // if (effectiveDt && effectiveDt?.from && effectiveDt?.to) {
    //   const formattedFromDate = getDateString(
    //     effectiveDt.from,
    //     this.shortDateFormat,
    //   );
    //   const formattedToDate = getDateString(
    //     effectiveDt.to,
    //     this.shortDateFormat,
    //   );
    //   effectiveRange = `${formattedFromDate},${formattedToDate}`;
    // }
    // if (premium && premium?.from !== '' && premium?.to !== '') {
    //   const formattedFrom =
    //     this.premiumOptions.find((x) => x.id == premium.from)?.value ??
    //     premium.from;
    //   const formattedTo =
    //     this.premiumOptions.find((x) => x.id == premium.to)?.value ??
    //     premium.to;
    //   premiumRange = `${formattedFrom},${formattedTo}`;
    // }
    // const filter = {
    //   quoteStatus: statusVal,
    //   effectiveDt: effectiveRange,
    //   trxDays: 0,
    //   underwriterId: underwriterIds,
    //   brokerageBORId: brokerIds,
    //   brokerageBranchBORId: branchIds,
    //   brokerageProducerBORId: producersIds,
    //   riskIds: productIds,
    //   createdDt: createRange,
    //   premiumRange: premiumRange,
    //   insuredName: insuredName,
    // };
    // return filter;
    return {
      page: 1,
      limit: 10,
      isDashboard: 1,
      active: 1,
    };
  }

  handleFilterTab(filterTab: any): void {
    this.filterTabs = this.filterTabs.map((tab: FilterTab, key: number) => {
      if (tab.name === filterTab.name) {
        this.currentFilterTabIndex = key;
        return { ...tab, active: true };
      } else {
        return { ...tab, active: false };
      }
    });
  }

  getTableColumns(tableType: string): void {
    if (tableType === 'task') {
      this.taskTableHeaders =
        this.taskTableData.length > 0
          ? Object.keys(this.taskTableData[0])
          : this.taskTableHeadersCopy;
    }
    if (tableType === 'followup') {
      this.followupTableHeaders =
        this.followupTableData.length > 0
          ? Object.keys(this.followupTableData[0])
          : this.followupTableHeadersCopy;
    }
  }

  async getTableData(
    filter: {
      tableType: string;
      pageNumber: number;
      limit: number;
      sort: string;
      scrollDirection: string;
    },
    appendData: boolean = false,
  ) {
    if (filter.tableType === 'task') {
      this.showTaskTblSpinner = true;
      await this.loadResults(filter, appendData);
    }
    if (filter.tableType === 'followup') {
      this.showFollowupTblSpinner = true;
      await this.loadResults(filter, appendData);
    }
  }

  getSortKeyForColumn(column: string, sortOrder: string): string {
    let keySplit: string[] = this.taskTableHeadSortKeys[column].split(',');
    let sortingKey: string = '';
    const keySplitLength = keySplit.length - 1;
    keySplit.map((sortKey: string, key: number) => {
      const appendKey = keySplitLength === key ? '' : ',';
      sortingKey += `${sortKey}:${sortOrder}${appendKey}`;
    });

    return sortingKey;
  }

  getTaskTableDataOnSort(filter: {
    page: number;
    sortColumn: string;
    sortOrder: string;
  }): void {
    let sortKey: string = this.getSortKeyForColumn(
      filter.sortColumn,
      filter.sortOrder,
    );

    this.getTableData({
      tableType: 'task',
      pageNumber: 1,
      limit: 10,
      sort: sortKey,
      scrollDirection: 'scrollingDown',
    });
  }
  getFollowupTableDataOnSort(filter: any): void {
    // console.log('filter : ', filter);
  }

  async getTaskTableDataOnScroll({
    sort,
    page,
    scrollDirection,
  }: any): Promise<void> {
    const filter = {
      tableType: 'task',
      pageNumber: page,
      limit: 10,
      sort: sort,
      scrollDirection,
    };
    await this.getTableData(filter, true);
  }
  async getFollowupTableDataOnScroll({
    sort,
    page,
    scrollDirection,
  }: any): Promise<void> {
    const filter = {
      tableType: 'followup',
      pageNumber: page,
      limit: 10,
      sort: sort,
      scrollDirection,
    };
    await this.getTableData(filter, true);
  }

  handleDataChange(
    scrollDirection: string,
    tableType: string,
    appendData: boolean,
  ): void {
    if (tableType === 'task') {
      this.getTableColumns('task');
      this.filterTabs = this.filterTabs.map((tab: FilterTab) => {
        if (tab.active) {
          return { ...tab, count: this.taskPagination.totalRecords };
        } else {
          return { ...tab, active: false };
        }
      });
    } else {
      this.getTableColumns('followup');
    }

    if (scrollDirection === 'scrollingDown') {
      // Append new data to the end
      this.taskTableData = appendData
        ? [...this.taskTableData, ...this.newTableData]
        : this.newTableData;
      tableType == 'task'
        ? (this.showTaskTblSpinner = false)
        : (this.showFollowupTblSpinner = false);

      // Ensure the data array length does not exceed 20 by removing the first 10 items
      // if (this.taskTableData.length > 20) {
      //   this.taskTableData = this.taskTableData.slice(10);
      // }
    }
    // else if (scrollDirection === 'scrollingUp') {
    //   // Prepend new data to the beginning
    //   this.taskTableData = [...newTableData, ...this.taskTableData];

    //   // Ensure the data array length does not exceed 20 by removing the last 10 items
    //   if (this.taskTableData.length > 20) {
    //     this.taskTableData = this.taskTableData.slice(0, 20);
    //   }
    // }
  }

  async onInsuredSearch(event) {
    const value = event.target.value;
    this.searchQuery = event.target.value;
    let filter = {
      tableType: 'task',
      pageNumber: 1,
      limit: 10,
      sort: '',
      scrollDirection: 'scrollingDown',
    };
    filter['insuredName'] && delete filter['insuredName'];
    this.searchQuery !== '' && (filter['insuredName'] = this.searchQuery);
    // this.filteredOptions = [];
    // this.filterSelectedOptions = undefined;
    if (event.key == 'Enter' && value.length >= 3 && value.length <= 40) {
      // this.showBodySpinner = true;
      // this.insuredSearchFilterOn = true;
      await this.loadResults(filter, false);
      // this.showBodySpinner = false;
    } else if (
      value?.length === 0 ||
      (event.key == 'Enter' && value?.length < 3)
    ) {
      // this.insuredSearchFilterOn = false;
      this.loadResults(filter, false);
    }
  }

  getTableDataOnRowClick({ rowData, column }: any): void {
    const quoteID = `${rowData?.['Quote ID']}`;
    if (column === 'Applicant name') {
      this.router.navigate(
        [`../${this.quoteFlowUrl}/new/product/form/${quoteID}`],
        {
          relativeTo: this.activatedRoute,
          skipLocationChange: true,
        },
      );
    }
  }

  getReferredBy(quoteData: any): string {
    return quoteData?.policyPeriod?.refferedBy
      ? quoteData?.policyPeriod?.refferedBy.firstName +
          ' ' +
          quoteData?.policyPeriod?.refferedBy.lastName
      : '';
  }
  getPendingInfoResons(quoteData: any): string[] {
    return quoteData?.policyRiskTrxes?.reason?.split(',') || [];
  }
  getWarningArray(quoteData: any): string[] {
    let resultArray = [];
    if (quoteData?.policy?.ofacSanction) {
      resultArray.push('OFAC');
    }
    if (
      quoteData?.policy?.insured?.industry?.IndustryRestrictedCodes?.length > 0
    ) {
      resultArray.push('Restricted industry');
    }
    if (
      ['referral', 'referred'].includes(quoteData?.Status) &&
      quoteData?.policyRiskTrxes?.reason
    ) {
      resultArray = {
        ...resultArray,
        ...quoteData?.policyRiskTrxes?.reason?.split(','),
      };
    }
    return resultArray;
  }

  handleStatusChanged({ rowIndex, selectedReasonDesc }) {
    this.taskTableData = this.taskTableData.map((data, index) => {
      if (index === rowIndex) {
        return {
          ...data,
          Status: QuoteFlowStatus.pendingInfo,
        };
      }
      return data;
    });
    this.unFilteredQuotesData = this.unFilteredQuotesData.map((data, index) => {
      if (index === rowIndex) {
        return {
          ...data,
          policyRiskTrxes: {
            ...data.policyRiskTrxes,
            quoteProcessStatus: {
              ...data.policyRiskTrxes?.quoteProcessStatus,
              description: QuoteFlowStatus.pendingInfo,
              subdomaincode: SUBDOMAINCODE_QUOTEFLOWSTATUS_PENDINGINFO,
            },
            reason: selectedReasonDesc,
          },
        };
      }
      return data;
    });
  }
}
