<div class="doc-container">
  <ng-container *ngIf="!showBodySpinner">
    <app-document-preview
      *ngIf="showDocPreview"
      [docUrl]="currentPreviewDocUrl"
      (handleClose)="closeDocPreview()"
    >
    </app-document-preview>
    <app-image-doc-preview
      *ngIf="showImagePreview"
      [fileType]="currentFileType"
      [url]="currentPreviewDocUrl"
      (handleClose)="closeDocPreview()"
    >
    </app-image-doc-preview>
    <app-regenerate-policy-document-popup-v2
      *ngIf="showDocTempSelectionPopup"
      [showModal]="showDocTempSelectionPopup"
      [details]="docPopupDetails"
      [insuredDetails]="details"
      (handleSuccess)="handleSuccessDocTemplate($event)"
      (handleActionConfirm)="
        this.permissionList[this.currentScreen] && handleDocLevelAction($event)
      "
      (handleClose)="handleCloseDocumentPopup()"
    ></app-regenerate-policy-document-popup-v2>
  </ng-container>

  <ng-container *ngIf="showModalSpinner">
    <div class="loader-wrapper">
      <div class="modal-body-spinner">
        <boxxds-spinner
          [containerStyle]="{
            width: '112px',
            height: '112px',
            '--b': '15px'
          }"
        ></boxxds-spinner>
      </div>
    </div>
  </ng-container>
  <div *ngIf="!showModalSpinner">
    <div style="position: relative" *ngIf="existingFiles.length === 0">
      <app-modal-alert></app-modal-alert>
    </div>
    <app-delete-confirmation-popup
      *ngIf="showDeleteFileConfirm"
      [details]="currentFile"
      [isConfirmDisabled]="isButtonDisabled"
      (handleClose)="closeDocPreview()"
      (handleConfirm)="!isButtonDisabled && handleDeleteConfirm()"
    >
    </app-delete-confirmation-popup>
    <div class="image-handler-section">
      <label
        class="drag-n-drop-wrapper"
        [class.noPointerEvents]="!isProductActive"
      >
        <div class="drag-n-drop">
          <div class="upload-icon-holder">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="none"
            >
              <path
                d="M6.66602 13.3333L9.99935 10M9.99935 10L13.3327 13.3333M9.99935 10V17.5M16.666 13.9524C17.6839 13.1117 18.3327 11.8399 18.3327 10.4167C18.3327 7.88536 16.2807 5.83333 13.7493 5.83333C13.5673 5.83333 13.3969 5.73833 13.3044 5.58145C12.2177 3.73736 10.2114 2.5 7.91602 2.5C4.46424 2.5 1.66602 5.29822 1.66602 8.75C1.66602 10.4718 2.36222 12.0309 3.48847 13.1613"
                stroke="#2D2D39"
                stroke-width="1.66667"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
          <h1>
            Click to upload <span>or drag and drop one or multiple files</span>
          </h1>
          <h6>JPG, JPEG, GIF, TXT, DOC, DOCX, PDF, PPTX (Max. 5Mb)</h6>
        </div>
        <input
          #fileInput
          type="file"
          (click)="this.handleAlertClear()"
          (change)="isProductActive && onFileDropped($event)"
          [disabled]="!isProductActive"
          [class.noPointerEvents]="!isProductActive"
          multiple
        />
      </label>
    </div>
    <div class="doc-table-container">
      <div class="table-title-container">
        <div class="title-section">
          <div class="title">
            <boxxds-heading
              [sz]="'5'"
              [text]="
                isPolicy
                  ? ('Policy documents' | translate)
                  : ('Quote documents' | translate)
              "
              [customStyle]="{
                color: '#2D2D39',
                fontFamily: 'Gibson',
                fontSize: '18px',
                fontWeight: '600',
                lineHeight: '28px',
                fontStyle: 'normal'
              }"
            >
            </boxxds-heading>
            <div class="status-badge">
              <span class="status-circle"></span>
              <p>
                {{ totalCount + " Records" }}
              </p>
            </div>
          </div>
          <boxxds-body
            class="description"
            [text]="
              isPolicy
                ? ('Keep track of the the documents on this policy.'
                  | translate)
                : ('Keep track of the the documents on this quote.' | translate)
            "
            [customStyle]="{
              fontFamily: 'Gibson',
              color: '#475467',
              fontSize: '14px',
              lineHeight: '20px',
              fontStyle: 'normal'
            }"
          >
          </boxxds-body>
        </div>
        <div class="search-container">
          <div class="search-input">
            <boxxds-textinput-v2
              [form]="docForm"
              [placeholder]="'Search type'"
              [control]="'searchKey'"
              [id]="'search'"
              [leftIcon]="'assets/img/workflow3/sidemenu/search.png'"
              [width]="'200px'"
              [maxLength]="'40'"
              (handleKeyUp)="handleSearch($event)"
            >
            </boxxds-textinput-v2>
          </div>
          <boxxds-button-v2
            *ngIf="isPolicy"
            [size]="'lg'"
            [buttonText]="
              'policy.policySlideOut.regeneratePolicyDocuments' | translate
            "
            class="regenerate-doc"
            [btnStyle]="{
              border: '1px solid var(--Primary-300, #FFBE3D)',
              background: '#fff',
              color: 'var(--Text-700, #2D2D39)',
              fontFamily: 'Roboto',
              fontSize: '14px',
              fontStyle: 'normal',
              fontWeight: '500',
              lineHeight: '16px'
            }"
            [isDisabled]="!isProductActive"
            [class.noPointerEvents]="!isProductActive"
            (click)="isProductActive && loadPolicyDocumentTemplates()"
          >
          </boxxds-button-v2>
        </div>
      </div>
      <div class="files-list custom-scrollbar" *ngIf="files.length > 0">
        <table>
          <thead>
            <tr>
              <th class="fixed-width-column"></th>
              <th class="fixed-width-column"></th>
              <th
                *ngFor="let key of tableHeaders; let indexOfHeaders = index"
                scope="col"
                [ngClass]="
                  ['common.fileName', 'common.fileDescription'].includes(key)
                    ? 'large-column'
                    : 'column-min-style'
                "
              >
                <boxxds-body
                  text="{{ key | translate }}"
                  sz="sm"
                  [customStyle]="{
                    color: '#475467',
                    textDecorationLine: 'none',
                    fontFamily: 'Gibson',
                    fontSize: '12px',
                    fontWeight: '500',
                    lineHeight: '18px',
                    fontStyle: 'normal'
                  }"
                >
                </boxxds-body>
              </th>
              <th
                class="fixed-width-column"
                *ngIf="permissionList[currentScreen] && isProductActive"
              ></th>
            </tr>
          </thead>
          <tbody class="custom-scrollbar">
            <tr *ngFor="let file of files; let i = index">
              <td class="fixed-width-column">
                <button
                  class="btn-preview"
                  (click)="handleDocPreview(file)"
                  [disabled]="isDisablePreview(file.name)"
                >
                  <img
                    [src]="
                      isDisablePreview(file.name)
                        ? 'assets/img/preview-disable.svg'
                        : 'assets/img/preview.svg'
                    "
                  />
                </button>
              </td>
              <td class="fixed-width-column">
                <button class="btn-download" (click)="handleDocDownload(file)">
                  <img src="assets/img/download.svg" />
                </button>
              </td>
              <td class="large-column">
                <div class="file-name">
                  <boxxds-body
                    [text]="file.name"
                    sz="sm"
                    [customStyle]="{
                      color: '#475467',
                      textDecorationLine: 'none',
                      fontFamily: 'Gibson',
                      fontSize: '14px',
                      fontWeight: '400',
                      lineHeight: '20px',
                      fontStyle: 'normal',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      maxWidth: '400px',
                      minWidth: '320px'
                    }"
                    title="{{ file.name }}"
                  >
                  </boxxds-body>
                </div>
              </td>
              <td>
                <boxxds-body
                  [text]="file.type"
                  sz="sm"
                  [customStyle]="{
                    color: '#333334',
                    textDecorationLine: 'none',
                    fontFamily: 'Gibson',
                    fontSize: '14px',
                    fontWeight: '400',
                    lineHeight: '20px',
                    fontStyle: 'normal'
                  }"
                >
                </boxxds-body>
              </td>
              <td>
                <boxxds-body
                  [text]="file.dateUpdated"
                  sz="sm"
                  [customStyle]="{
                    color: '#333334',
                    textDecorationLine: 'none',
                    fontFamily: 'Gibson',
                    fontSize: '14px',
                    fontWeight: '400',
                    lineHeight: '20px',
                    fontStyle: 'normal'
                  }"
                >
                </boxxds-body>
              </td>
              <td>
                <boxxds-body
                  [text]="file.updatedBy"
                  sz="sm"
                  [customStyle]="{
                    color: '#475467',
                    textDecorationLine: 'none',
                    fontFamily: 'Gibson',
                    fontSize: '12px',
                    fontWeight: '500',
                    lineHeight: '18px',
                    fontStyle: 'normal'
                  }"
                >
                </boxxds-body>
              </td>
              <td class="large-column">
                <div class="">
                  <boxxds-body
                    [text]="file.description"
                    sz="sm"
                    [customStyle]="{
                      color: '#333334',
                      textDecorationLine: 'none',
                      fontFamily: 'Gibson',
                      fontSize: '14px',
                      fontWeight: '400',
                      lineHeight: '20px',
                      fontStyle: 'normal',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      maxWidth: '400px',
                      minWidth: '320px'
                    }"
                    title="{{ file.description }}"
                  >
                  </boxxds-body>
                </div>
              </td>
              <td
                class="fixed-width-column"
                *ngIf="permissionList[currentScreen] && isProductActive"
              >
                <button
                  class="btn-delete"
                  (click)="handleDelete(i, file)"
                  [disabled]="!file.deletable"
                >
                  <img
                    class="delete-icon"
                    [src]="
                      !file.deletable
                        ? 'assets/img/trash-disable.svg'
                        : 'assets/img/trash.svg'
                    "
                    alt="delete"
                  />
                  <boxxds-body [text]="" sz="xxs" [customStyle]="{}">
                  </boxxds-body>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="no-records-holder" *ngIf="files.length === 0">
        <div class="no-records-ellipse">
          <img src="assets/img/no-records.png" alt="" />
          <boxxds-body
            [text]="'common.noRecords' | translate"
            sz="md"
            [customStyle]="{ color: '#616162' }"
          >
          </boxxds-body>
        </div>
      </div>
      <div class="document-details">
        <div
          class="d-flex"
          *ngFor="
            let data of files
              | paginate
                : {
                    id: 'activitylog-pagination',
                    itemsPerPage: itemsPerPage,
                    currentPage: currentPage,
                    totalItems: filesData?.pagination?.totalRecords
                  }
          "
        ></div>

        <div class="table-footer" *ngIf="filesData.pagination.totalPages > 1">
          <pagination-template
            id="activitylog-pagination"
            #p="paginationApi"
            (pageChange)="populateDocuments($event)"
          >
            <div class="pagination-container">
              <div
                class="previous-btn-section"
                [ngClass]="{ 'prev-btn-disabled': p.isFirstPage() }"
              >
                <button
                  class="pagination-prev-btn"
                  [attr.disabled]="p.isFirstPage() ? true : null"
                  (keyup.enter)="p.previous()"
                  (click)="!p.isFirstPage() && p.previous()"
                  tabindex="0"
                >
                  <img src="assets/img/arrow-right.svg" alt="next-arrow" />
                  <a class="tbl-page-link" aria-label="Previous">Previous</a>
                </button>
              </div>
              <div class="page-number-section">
                <button
                  *ngFor="let page of p.pages"
                  [class.active]="p.getCurrent() === page.value"
                  class="tbl-page-item"
                  (keyup.enter)="p.setCurrent(page.value)"
                  (click)="p.setCurrent(page.value)"
                  tabindex="0"
                >
                  <a
                    [class.active]="p.getCurrent() === page.value"
                    class="tbl-page-link"
                  >
                    {{ page.label }}
                  </a>
                </button>
              </div>
              <div
                class="next-btn-section"
                [ngClass]="{ 'next-btn-disabled': p.isLastPage() }"
              >
                <button
                  class="pagination-next-btn"
                  [attr.disabled]="p.isLastPage() ? true : null"
                  (keyup.enter)="p.next()"
                  (click)="!p.isLastPage() && p.next()"
                  tabindex="0"
                >
                  <a class="tbl-page-link">Next</a>
                  <img src="assets/img/arrow-right.svg" alt="next-arrow" />
                </button>
              </div>
            </div>
          </pagination-template>
        </div>
      </div>
    </div>
  </div>
</div>

<app-document-action-popup
  *ngIf="existingFiles.length > 0"
  [showModal]="existingFiles.length > 0"
  [existingFileName]="existingFileName"
  [existingAlertData]="existingAlertData"
  [suggestedFileName]="suggestedFileName"
  (handleClose)="handleCloseExistingFilesUpload()"
  (handleActionConfirm)="
    !isUploadingProgress && handleDocumentActionConfirm($event)
  "
  [isUploadingProgress]="isUploadingProgress"
  [isGettingSuggestionProgress]="isGettingSuggestionProgress"
>
</app-document-action-popup>
