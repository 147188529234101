<div class="spinner-container">
  <div class="spinner-holder-submission" *ngIf="showBodySpinner">
    <div class="body-spinner-submission">
      <div class="body-spinner-wrapper">
        <boxxds-spinner
          [containerStyle]="{
            width: '112px',
            height: '112px',
            '--b': '15px'
          }"
        ></boxxds-spinner>
      </div>
    </div>
  </div>
</div>
<app-navigation-v2
  *ngIf="isRestrictedRoleSubmission"
  [showHeaderBanner]="true"
  [mainHeading]="'headerName'"
  [subHeading]="'sub'"
  [isNeeded]="false"
  [showHeader]="true"
  [items]="breadCrumbArray"
  (suggestedSearchSelected)="handleGlobalSearch($event)"
>
</app-navigation-v2>
<section class="title-section" *ngIf="isRestrictedRoleSubmission">
  <div class="max-width-wrapper">
    <div class="title-description-container">
      <div class="title-container">
        <boxxds-heading
          class="title"
          [sz]="'6'"
          [text]="
            'workFlow3.components.newSubmission.heading.newBusinessSubmission'
              | translate
          "
          type="bold"
        ></boxxds-heading>
      </div>
    </div>
  </div>
</section>
<section class="newSubmissionWrapperWF3">
  <div
    class="submissionSection"
    [ngStyle]="{ padding: isRestrictedRoleSubmission ? '45px 0' : '144px 0' }"
  >
    <div class="alert-container-wf3" *ngIf="alertData?.show">
      <boxxds-alert-v2
        *ngIf="alertData?.show"
        [type]="alertData?.type"
        [headerText]="alertData?.header | translate"
        [bodyText]="alertData?.body | translate"
        (handleCloseEvent)="handleAlertClose()"
      ></boxxds-alert-v2>
    </div>

    <div
      class="pageHeading"
      *ngIf="!showBodySpinner && !isRestrictedRoleSubmission"
    >
      <boxxds-body
        sz="md"
        [text]="
          'workFlow3.components.newSubmission.heading.startQuote' | translate
        "
        [customStyle]="{
          color: '#292933',
          fontFamily: 'Gibson',
          fontStyle: 'normal',
          fontSize: '30px',
          fontWeight: '600',
          lineHeight: '38px'
        }"
      ></boxxds-body>
    </div>
    <ng-container *ngIf="!showBodySpinner">
      <div
        class="main-card"
        [ngClass]="{ 'section-submission': isRestrictedRoleSubmission }"
      >
        <ng-container *ngIf="!showBodySpinner">
          <div class="rowInsurer">
            <div class="columnInsurerLeft">
              <boxxds-body
                sz="md"
                [text]="
                  (isRestrictedRoleSubmission
                    ? 'workFlow3.components.newSubmission.heading.applicantType'
                    : 'workFlow3.components.newSubmission.heading.insuredType'
                  ) | translate
                "
                [customStyle]="{
                  fontFamily: 'Gibson',
                  color: '#2D2D39',
                  fontSize: '14px',
                  fontStyle: 'normal',
                  fontWeight: '600',
                  lineHeight: '20px'
                }"
                [isRequired]="true"
              ></boxxds-body>
              <boxxds-body
                sz="md"
                [text]="
                  (isRestrictedRoleSubmission
                    ? 'workFlow3.components.newSubmission.subHeading.applicantType'
                    : 'workFlow3.components.newSubmission.subHeading.insureType'
                  ) | translate
                "
                [customStyle]="{
                  fontFamily: 'Gibson',
                  color: '#475467',
                  fontSize: '14px',
                  fontStyle: 'normal',
                  fontWeight: '400',
                  lineHeight: '20px'
                }"
              ></boxxds-body>
            </div>

            <div class="columnInsurerRight d-flex">
              <boxxds-radio-v2
                [items]="radioBusiness"
                [control]="'insured_type'"
                [form]="insuredTypeForm"
                [formSubmitted]="false"
                [radioStyle]="{ marginTop: '1px' }"
                [containerStyle]="{ marginRight: '10px' }"
                [labelStyle]="{
                  fontFamily: 'Gibson',
                  color: '#2D2D39',
                  fontSize: '14px',
                  fontStyle: 'normal',
                  fontWeight: '500',
                  lineHeight: '20px'
                }"
                (handleChange)="
                  !isExistingInsuredFormEdit &&
                    !insuredId &&
                    handleInsuredTypeChange()
                "
                [class.noPointerEvents]="isExistingInsuredFormEdit || insuredId"
              >
              </boxxds-radio-v2>
              <boxxds-radio-v2
                [items]="radioIndividual"
                [control]="'insured_type'"
                [form]="insuredTypeForm"
                [formSubmitted]="false"
                [radioStyle]="{ marginTop: '1px' }"
                [labelStyle]="{
                  fontFamily: 'Gibson',
                  color: '#2D2D39',
                  fontSize: '14px',
                  fontStyle: 'normal',
                  fontWeight: '500',
                  lineHeight: '20px'
                }"
                (handleChange)="
                  !isExistingInsuredFormEdit &&
                    !insuredId &&
                    handleInsuredTypeChange()
                "
                [class.noPointerEvents]="isExistingInsuredFormEdit || insuredId"
              >
              </boxxds-radio-v2>
            </div>
          </div>

          <div class="lineDivider"></div>

          <div *ngIf="typeBusinessInsured === false">
            <ng-container>
              <app-individual-insured-form-workflow3
                [form]="addIndividualForm"
                (enableProducerInfo)="showProducerInfo($event)"
                [form$]="form$"
                [checkValidation]="checkValidation"
                [insuredId]="insuredId"
                [isExistingInsuredFormEdit]="isExistingInsuredFormEdit"
                [productId]="productId"
                [productName]="productName"
                [fromSubmissionFlow]="true"
                [disableProduct]="disableProduct"
                (selectInsured)="selectInsured($event)"
                (clearInsuredForm)="clearInsuredForm()"
              >
              </app-individual-insured-form-workflow3>
            </ng-container>
          </div>

          <div *ngIf="typeBusinessInsured === true">
            <ng-container>
              <app-business-insured-form-workflow3
                [form]="addCompanyForm"
                [form$]="form$"
                [contactForm]="contactForm"
                [checkValidation]="checkValidation"
                [checkContactValidation]="checkContactValidation"
                [fromSubmissionFlow]="true"
                [disableProduct]="disableProduct"
                [insuredId]="insuredId"
                [isExistingInsuredFormEdit]="isExistingInsuredFormEdit"
                [isAtLeastOneFieldFilled]="isAtLeastOneFieldFilled"
                [productId]="productId"
                [productName]="productName"
                (enableProducerInfo)="showProducerInfo($event)"
                (selectInsured)="selectInsured($event)"
                (clearInsuredForm)="clearInsuredForm()"
                (setRegionID)="setRegionID($event)"
                (addContactEvent)="addContact()"
                (setNAICSData)="setNAICSData($event)"
                [showIndustrySection]="showIndustrySection"
              >
              </app-business-insured-form-workflow3>
            </ng-container>
          </div>
        </ng-container>
      </div>
    </ng-container>
    <!-- <ng-container *ngIf="!showBodySpinner && enableProducerInfo"> -->
    <div
      class="main-card producer-section"
      [ngClass]="{ 'producer-submission': isRestrictedRoleSubmission }"
      [ngStyle]="{
        display: !showBodySpinner && enableProducerInfo ? 'block' : 'none'
      }"
    >
      <app-producer-information
        [form]="typeBusinessInsured ? addCompanyForm : addIndividualForm"
        (formChange)="handleFormChange($event)"
        [productId]="form.get('addCompanyForm').value['productId']"
        [regionId]="regionId"
        [insuredName]="
          typeBusinessInsured
            ? insuredName || form.get('addCompanyForm').value['businessName']
            : insuredName ||
              form.get('addIndividualForm').value['firstName'] +
                (' ' + form.get('addIndividualForm').value['middleName']) +
                (' ' + form.get('addIndividualForm').value['lastName'])
        "
        [formSubmitted]="formSubmitted"
        [isSubmission]="isRestrictedRoleSubmission"
        (handleAlertInfo)="handleAlertInfo($event)"
        (handleSaveInsured)="addOrUpdateInsurer(true)"
      >
      </app-producer-information>
      <!-- product details -->
      <div
        class="lineDivider mb-0 mt-0 submission-margin"
        *ngIf="isRestrictedRoleSubmission"
      ></div>
      <div
        class="rowInsurer mt-4 submission-margin mb-4"
        *ngIf="isRestrictedRoleSubmission"
      >
        <div class="columnInsurerLeft">
          <boxxds-body
            sz="md"
            [text]="
              'workFlow3.components.newSubmission.heading.riskInfo' | translate
            "
            [customStyle]="{
              color: '#2D2D39',
              fontFamily: 'Gibson',
              fontSize: '14px',
              fontStyle: 'normal',
              fontWeight: '600',
              lineHeight: '20px'
            }"
          ></boxxds-body>
          <boxxds-body
            sz="md"
            [text]="
              'workFlow3.components.newSubmission.subHeading.riskInfo'
                | translate
            "
            [customStyle]="{
              fontFamily: 'Gibson',
              color: '#475467',
              fontSize: '14px',
              fontStyle: 'normal',
              fontWeight: '400',
              lineHeight: '20px'
            }"
          ></boxxds-body>
        </div>

        <div class="columnInsurerRight">
          <boxxds-dropdown-v2
            [label]="
              'workFlow3.components.newSubmission.label.risk' | translate
            "
            [placeholder]="'placeholder.select' | translate"
            [options]="products"
            [isReadonly]="disableProduct"
            [form]="form"
            [control]="'productName'"
            class="input-fields"
            [requiredErrMsg]="
              'workFlow3.components.brokerCommissionPopup.error.requiredErrMsg'
                | translate
            "
            [formSubmitted]="checkValidation"
            [currentValue]="form.get('addCompanyForm').value['productName']"
            (currentValueChange)="onChangeProduct($event)"
            [isDisabled]="disableProduct"
            [isRequired]="true"
          >
          </boxxds-dropdown-v2>
        </div>
      </div>
      <div
        class="lineDivider mb-0"
        *ngIf="
          typeBusinessInsured === true &&
          showIndustrySection &&
          fromSubmissionFlow &&
          isRestrictedRoleSubmission
        "
      ></div>
      <div class="lineDivider mb-0" *ngIf="isRestrictedRoleSubmission"></div>
      <div
        class="rowInsurer mt-4 submission-margin mb-4"
        *ngIf="isRestrictedRoleSubmission"
      >
        <div class="columnInsurerLeft">
          <boxxds-body
            sz="md"
            [text]="
              'workFlow3.components.newSubmission.heading.dateInfo' | translate
            "
            [customStyle]="{
              color: '#2D2D39',
              fontFamily: 'Gibson',
              fontSize: '14px',
              fontStyle: 'normal',
              fontWeight: '600',
              lineHeight: '20px'
            }"
          ></boxxds-body>
          <boxxds-body
            sz="md"
            [text]="
              'workFlow3.components.newSubmission.subHeading.dateInfo'
                | translate
            "
            [customStyle]="{
              fontFamily: 'Gibson',
              color: '#475467',
              fontSize: '14px',
              fontStyle: 'normal',
              fontWeight: '400',
              lineHeight: '20px'
            }"
          ></boxxds-body>
        </div>

        <div class="columnInsurerRight">
          <boxxds-datepicker-v2
            [placeholder]="'12/29/2024'"
            [label]="
              'workFlow3.components.newSubmission.label.receivedOn' | translate
            "
            [bsValue]="form.get('addCompanyForm').value['receivedOn']"
            [dateFormat]="shortDateFormat"
            (bsValueChange)="handleReceivedOnDate($event)"
            [style]="{ width: '100%' }"
            [inputOuterStyle]="{ width: '100%' }"
            [containerStyle]="{ marginBottom: '24px' }"
            [isDisabled]="false"
            [isRequired]="true"
          >
          </boxxds-datepicker-v2>
          <boxxds-datepicker-with-tbd
            [placeholder]="'common.select' | translate"
            [label]="
              'workFlow3.components.newSubmission.label.needBy' | translate
            "
            [showErr]="isNeedByValid().show"
            [errMsg]="isNeedByValid().msg | translate"
            [bsValue]="form.get('addCompanyForm').value['needBy']"
            [dateFormat]="shortDateFormat"
            (bsValueChange)="handleNeedByDate($event)"
            [style]="{ width: '100%' }"
            [isDateDisabled]="false"
            [isRequired]="true"
            [isTBD]="isNeedByDtTBD"
          >
          </boxxds-datepicker-with-tbd>
        </div>
      </div>
    </div>
    <div class="lineDivider mb-0" *ngIf="isRestrictedRoleSubmission"></div>

    <!-- </ng-container> -->

    <div
      class="submission-footer d-flex justify-content-end"
      *ngIf="!showBodySpinner"
    >
      <boxxds-button-v2
        *ngIf="!isRestrictedRoleSubmission"
        [size]="'lg'"
        [type]="'submit'"
        [btnStyle]="{ padding: '12px' }"
        [buttonText]="
          'workFlow3.components.newSubmission.button.exit' | translate
        "
        [btnTextStyle]="{ marginRight: '12px', textDecoration: 'underline' }"
        [btnClass]="'link gray'"
        (click)="handleExitFlow()"
      >
      </boxxds-button-v2>
      <boxxds-button-v2
        [size]="'lg'"
        [type]="'submit'"
        [buttonText]="saveBtnTxt | translate"
        [btnClass]="'primary'"
        (click)="addOrUpdateInsurer()"
        [isDisabled]="!DoCheck()"
        [btnStyle]="
          isRestrictedRoleSubmission
            ? { width: '170px', marginRight: '-28px' }
            : {}
        "
      >
      </boxxds-button-v2>
    </div>
  </div>
</section>
<app-modal-market-reservation
  *ngIf="showMarketReservationModal"
  [showModal]="showMarketReservationModal"
  [existingQuoteOptionData]="actionPopupDetails"
  (handleClose)="closeModalMarketReservation()"
  (seeExistingQuote)="handleSeeExistingQuote()"
>
</app-modal-market-reservation>

<app-modal-insured-state-change
  *ngIf="showStateChangeModal"
  [showModal]="showStateChangeModal"
  [existingQuoteOptionData]="actionPopupDetails"
  (handleClose)="closeModalInsuredStateChange()"
  (handleDecline)="handleDeclineQuote(true)"
  (handleNewInsured)="handleCreateNewInsured()"
  [isSubmissionFlow]="true"
>
</app-modal-insured-state-change>
<app-modal-risk-analysis
  *ngIf="showRiskAnalysisUpdateModal"
  [showModal]="showRiskAnalysisUpdateModal"
  [existingQuoteOptionData]="actionPopupDetails"
  (handleClose)="closeModalRiskAnalysisUpdate()"
  (handleDiscard)="handleDiscardRecalculate($event)"
  (handleContinue)="handleRiskAnalysisChange()"
  [isSubmissionFlow]="true"
>
</app-modal-risk-analysis>

<app-modal-premium-recalculate
  *ngIf="showRecalcConfModal"
  [showModal]="showRecalcConfModal"
  [existingQuoteOptionData]="actionPopupDetails"
  (handleClose)="closeModalRecalculateConf()"
  (handleSubmissionRecalculate)="handleSubmissionRecalculate($event, null)"
  (handleDiscardRecalculate)="handleDiscardRecalculate($event)"
  [isSubmissionFlow]="true"
>
</app-modal-premium-recalculate>
<app-modal-existing-quote
  *ngIf="showExistingQuoteModal"
  [showModal]="showExistingQuoteModal"
  [existingQuoteOptionData]="actionPopupDetails"
  (handleClose)="closeExistingQuote()"
  (seeExistingQuote)="handleSeeExistingQuote()"
>
</app-modal-existing-quote>
<app-restricted-industry-or-sanction-popup
  *ngIf="showSanctionModal"
  [details]="actionPopupDetails"
  [tableData]="sanctionPayload"
  (handleClose)="closeSanctionPopup()"
  (handleSubmit)="handleSanctionProceed()"
  (handleDeclineAndSaveChanges)="handleDeclineQuote()"
  (handleDiscardChanges)="handleDiscardRecalculate($event)"
  [columnsToHide]="columnsToHide"
  [sortableColumns]="sortableColumns"
  [iconColumns]="iconColumns"
  [customColumnWidth]="customColumnWidth"
  (sortHandler)="handleSort($event)"
  (columnClickHandler)="columnClickHandler($event)"
  [isRestrictedIndustry]="isRestrictedIndustry"
  [hasQuote]="quoteExist === 1 || quoteExist === 2"
>
</app-restricted-industry-or-sanction-popup>
<app-modal-submisison-submitted
  *ngIf="showSubmissionSubmittedModal"
  [showModal]="showSubmissionSubmittedModal"
  [existingQuoteOptionData]="actionPopupDetails"
  (handleClose)="closeModalSubmissionSubmitted()"
  (handleStartSubmission)="handleStartSubmission($event)"
  (handleGotoDashboard)="handleGotoDashboard($event)"
>
</app-modal-submisison-submitted>
