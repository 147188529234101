<div
  id="scrollable-pagination-table-wrapper"
  class="scrollable-pagination-table-wrapper custom-scrollbar"
  [ngClass]="{
    'container-default-height': isDefaultHeight && !isExpanded,
    'container-expanded-height': !isDefaultHeight && isExpanded,
    'container-collapsed-height':
      tableData.length !== 0 && !isDefaultHeight && !isExpanded,
    'container-collapsed-empty-height':
      tableData.length === 0 && !isDefaultHeight && !isExpanded
  }"
>
  <table
    class="scrollable-pagination-table"
    [ngStyle]="{ opacity: isLoading ? 0 : 1 }"
  >
    <thead>
      <tr>
        <ng-container *ngFor="let col of tableColumns">
          <th *ngIf="!tableColumnsExcluded.includes(col)">
            <div
              class="head-col"
              (click)="handleSort(col)"
              [ngStyle]="{
                justifyContent: ['Status', 'Need by'].includes(col)
                  ? 'center'
                  : 'unset'
              }"
            >
              <p class="btn-text">{{ col }}</p>
              <div class="sort-icon" *ngIf="currentSortColumn === col">
                <img
                  src="assets/img/arrow-down.svg"
                  alt="sort-icon"
                  [ngStyle]="{
                    transform:
                      sortOrder === 'asc' ? 'rotate(0deg)' : 'rotate(180deg)',
                    transitionDuration: '0.4s'
                  }"
                />
              </div>
            </div>
          </th>
        </ng-container>
        <th>
          <div class="head-col" (click)="handleTableCollapseAndExpand()">
            <img
              src="assets/img/chevron-down-black.svg"
              alt="collapse-icon"
              class="table-collapse-expand-btn"
              [ngStyle]="{
                transform: !isExpanded ? 'rotate(0deg)' : 'rotate(180deg)',
                transitionDuration: '0.4s'
              }"
            />
          </div>
        </th>
      </tr>
    </thead>
    <tbody
      [ngClass]="{
        'default-height': !isExpanded,
        'collapsed-height': isExpanded
      }"
    >
      <ng-container *ngIf="tableData.length > 0">
        <tr *ngFor="let rowData of tableData; let tableDataIdx = index">
          <ng-container *ngFor="let col of tableColumns">
            <td
              *ngIf="!tableColumnsExcluded.includes(col)"
              class="table-row"
              [ngStyle]="{
                width: ['Status', 'Submission Date'].includes(col)
                  ? '150px'
                  : 'auto',
                paddingBottom:
                  showStatusChangedBadge &&
                  statusChangedBadgeIndex === tableDataIdx
                    ? '0px'
                    : '12px'
              }"
            >
              <div class="body-col-data">
                <ng-container [ngSwitch]="col">
                  <div
                    *ngSwitchCase="'Applicant name'"
                    class="text-sm-semibold ellipsis"
                    [ngStyle]="{
                      minWidth: '105px',
                      maxWidth: '105px',
                      cursor: 'pointer'
                    }"
                    title="{{ rowData[col] }}"
                    (click)="handleRowClick(rowData, col, tableDataIdx)"
                  >
                    {{ rowData[col] }}
                  </div>
                  <div
                    *ngSwitchCase="'Status'"
                    class="text-sm-regular"
                    [ngStyle]="{
                      minWidth: 'max-content',
                      width: '100%',
                      display: 'flex',
                      flexDirection: 'column'
                    }"
                  >
                    <app-status-badge-v2
                      (click)="handleStatusClick($event, tableDataIdx)"
                      [rowData]="rowData"
                      [columnName]="col"
                    ></app-status-badge-v2>
                    <div
                      class="status-change-popup"
                      *ngIf="tableDataIdx === statusChangePopupIdx"
                      (clickOutside)="closeStatusChangePopup()"
                      [ngStyle]="statusChangePositionalStyles"
                    >
                      <boxxds-dropdown-v2
                        [form]="form"
                        [control]="'reason'"
                        [placeholder]="'common.select' | translate"
                        (currentValueChange)="reasonHandler($event)"
                        [options]="pendingInfoReasons"
                        [label]="
                          'dashboard.label.pendingInfoReason' | translate
                        "
                        [hasScrollablePagination]="true"
                        [dropdownListStyle]="{ maxHeight: '130px' }"
                        [ngStyle]="{ width: '350px' }"
                      ></boxxds-dropdown-v2>
                      <boxxds-button-v2
                        [isDisabled]="buttonDisabled"
                        [size]="'sm'"
                        [btnClass]="'secondary gray'"
                        [btnType]="'only'"
                        [isDropdown]="false"
                        [leftImage]="'check'"
                        [ngStyle]="{
                          marginLeft: '20px',
                          alignSelf: 'flex-end'
                        }"
                        [btnStyle]="{ padding: '11px' }"
                        [leftImageStyle]="{
                          filter: buttonDisabled
                            ? 'brightness(0) saturate(100%) invert(73%) sepia(11%) saturate(405%) hue-rotate(179deg) brightness(87%) contrast(88%)'
                            : 'brightness(0) saturate(100%) invert(14%) sepia(8%) saturate(1361%) hue-rotate(202deg) brightness(98%) contrast(89%)'
                        }"
                        (handleClick)="handleStatusChange(tableDataIdx)"
                      >
                      </boxxds-button-v2>
                    </div>
                    <div
                      class="status-changed-badge"
                      *ngIf="
                        showStatusChangedBadge &&
                        statusChangedBadgeIndex === tableDataIdx
                      "
                    >
                      <img src="assets/img/check-circle.svg" alt="" />
                      <p>{{ "dashboard.info.statusChanged" | translate }}</p>
                    </div>
                  </div>
                  <div
                    *ngSwitchCase="'Source'"
                    class="text-xs-medium"
                    [ngStyle]="{
                      minWidth: '57px',
                      maxWidth: '57px',
                      borderRadius: '6px',
                      border: '1px solid #EAECF0',
                      backgroundColor: '#FFF',
                      padding: '2px 6px',
                      textAlign: 'center'
                    }"
                  >
                    {{ rowData[col] }}
                  </div>
                  <div
                    *ngSwitchCase="'Submission Date'"
                    class="multiline-data-col"
                    [ngStyle]="{
                      minWidth: '110px',
                      alignItems: 'flex-start'
                    }"
                  >
                    <p class="text-sm-regular">{{ rowData[col].date }}</p>
                    <p class="text-sm-regular">
                      <app-status-badge-v2
                        [rowData]="rowData"
                        [columnName]="col"
                      ></app-status-badge-v2>
                    </p>
                  </div>
                  <div
                    *ngSwitchCase="'Need by'"
                    class="text-sm-regular"
                    [ngStyle]="{
                      width: '100%',
                      textAlign: 'center',
                      minWidth: 'max-content'
                    }"
                  >
                    {{ rowData[col] ?? "-" }}
                  </div>
                  <div
                    *ngSwitchCase="'Industry'"
                    class="text-sm-regular ellipsis"
                    [ngStyle]="{ minWidth: '87px', maxWidth: '87px' }"
                    title="{{ rowData[col] }}"
                  >
                    {{ rowData[col] }}
                  </div>
                  <div
                    *ngSwitchCase="'Revenue'"
                    class="text-sm-regular"
                    [ngStyle]="{
                      minWidth: '66px',
                      maxWidth: '66px',
                      textAlign: 'right'
                    }"
                  >
                    {{
                      (
                        0 | currency: currency : "symbol-narrow" : "1.0-0"
                      ).charAt(0)
                    }}{{ rowData[col] | abbreviateNumber: 2 }}
                  </div>
                  <div *ngSwitchCase="'Broker'" class="multiline-data-col">
                    <p
                      class="text-sm-regular"
                      [ngStyle]="{ minWidth: '110px', maxWidth: '110px' }"
                      title="{{ rowData[col].producer }}"
                    >
                      {{ rowData[col].producer }}
                    </p>
                    <div
                      [ngStyle]="{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '4px'
                      }"
                    >
                      <p
                        class="text-sm-regular-lite ellipsis"
                        [ngStyle]="{ minWidth: '90px', maxWidth: '90px' }"
                        title="{{ rowData[col].teamName ?? '-' }}"
                      >
                        {{ rowData[col].teamName ?? "-" }}
                      </p>
                      <div>
                        <img
                          src="assets/img/info-circle.svg"
                          alt="info-icon"
                          [ngStyle]="{
                            width: '12px',
                            height: '12px',
                            cursor: 'pointer'
                          }"
                          (mouseenter)="tooltipInfo.showTooltip($event)"
                        />
                      </div>
                      <app-info-tooltip-v2
                        #tooltipInfo
                        [tooltipObject]="setupTooltipBranch(rowData)"
                      ></app-info-tooltip-v2>
                    </div>
                  </div>
                  <div
                    *ngSwitchCase="'Brokerage'"
                    class="text-sm-regular ellipsis"
                    [ngStyle]="{ minWidth: '117px', maxWidth: '117px' }"
                    title="{{ rowData[col] }}"
                  >
                    {{ rowData[col] }}
                  </div>
                  <div *ngSwitchDefault class="text-sm-regular">
                    {{ rowData[col] }}
                  </div>
                </ng-container>
              </div>
            </td>
          </ng-container>
          <td>
            <div class="reassign-btn">Reassign</div>
          </td>
          <div
            class="unread-indicator ml-1"
            [ngStyle]="{
              top:
                showStatusChangedBadge &&
                statusChangedBadgeIndex === tableDataIdx
                  ? '64%'
                  : '50%'
            }"
            *ngIf="rowData?.['isUnread']"
          ></div>
        </tr>
      </ng-container>
      <ng-container *ngIf="tableData.length === 0">
        <tr>
          <td
            [colSpan]="tableColumns.length"
            [ngStyle]="{
              height: !isDefaultHeight && !isExpanded ? 'auto' : '504px'
            }"
          >
            <app-ripple-animation
              [message]="noDataMessage"
            ></app-ripple-animation>
          </td>
        </tr>
      </ng-container>
    </tbody>
  </table>
  <div class="loader" *ngIf="isLoading"></div>
</div>
