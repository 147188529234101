<div class="holder">
  <div
    class="alert-holder"
    *ngIf="alerts.length > 0 && !isWorkflow3 && !showHeaderV3"
  >
    <div
      *ngFor="let alert of alerts; let i = index"
      class="alert-wrapper"
      [ngStyle]="alert.wrapperStyle"
    >
      <boxxds-alert
        [type]="alert.type"
        [linkData]="alert.linkData"
        [headerText]="alert.headerText | translate"
        [bodyText]="alert.bodyText | translate"
        (handleClick)="handleAlertLinkClickEvent($event, i)"
        (handleCloseEvent)="handleAlertClose(i)"
        [containerStyle]="{
          position: 'relative',
          top: 'unset',
          transition: 'all 0.3s ease-out',
          marginTop: '8px',
          marginBottom: '0'
        }"
      >
      </boxxds-alert>
    </div>
  </div>
  <ng-container *ngIf="alerts.length > 0 && isWorkflow3 && !showHeaderV3">
    <div
      class="alert-holder-workflow3"
      [ngClass]="{
        'quotes-grid': isQuotesGridV2,
        'submission-step-1': isSubmissionFirstStep
      }"
    >
      <div
        *ngFor="let alert of alerts; let i = index"
        class="alert-wrapper"
        [ngStyle]="alert.wrapperStyle"
      >
        <boxxds-alert-v2
          [type]="alert.type"
          [linkData]="alert.linkData"
          [headerText]="alert.headerText | translate"
          [bodyText]="alert.bodyText | translate"
          (handleClick)="handleAlertLinkClickEvent($event, i)"
          (handleCloseEvent)="handleAlertClose(i)"
          [containerStyle]="{
            position: 'relative',
            top: 'unset',
            transition: 'all 0.3s ease-out',
            marginTop: '0',
            marginBottom: '8px'
          }"
        >
        </boxxds-alert-v2>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="showMaintenanceBanner()">
    <div
      class="maintenance-banner-holder"
      [ngClass]="{
        'quotes-grid': isQuotesGridV2 || isInsuredOrBrokerModule,
        'submission-step-1': isSubmissionFirstStep
      }"
    >
      <app-maintenance-banner></app-maintenance-banner>
    </div>
  </ng-container>
  <app-logout-popup
    [showModal]="showLogoutPopup"
    (cancelClickHandler)="cancelClickHandler()"
    (logoutClickHandler)="logoutClickHandler()"
  ></app-logout-popup>
  <div class="header-holder" *ngIf="!isWorkflow3 && !showHeaderV3">
    <div class="header">
      <a
        class="logo-link"
        [routerLink]="['/dashboard/home']"
        [queryParams]="{ mock: mockExternalAPIs }"
        skipLocationChange="true"
        ><img src="./assets/img/dashboard/boxx.png" alt="logo"
      /></a>
      <app-navigation [marginLeft]="'50px'"></app-navigation>
      <app-search-v2
        [id]="'search'"
        (suggestedSearchSelected)="handleSearch($event)"
      ></app-search-v2>
    </div>
  </div>

  <!-- workflo3 navigation -->
  <div
    class="header-holder-v2"
    *ngIf="showWorkFlow3navigation"
    [ngClass]="{ wf3: isWorkflow3 }"
  >
    <div class="header">
      <app-navigation-v2
        [showHeader]="true"
        [showHeaderBanner]="false"
        (suggestedSearchSelected)="handleSearch($event)"
        [items]="getCrumbs()"
        [badgeData]="'0 Records'"
        (buttonClicked)="handleStartNewQuote()"
      ></app-navigation-v2>
    </div>
  </div>
  <!-- new header component start -->
  <div
    class="header-holder-v3"
    [ngClass]="{ 'justify-content-end': isRestrictedRoleSubmission }"
    *ngIf="showHeaderV3"
  >
    <div
      class="two-button-toggle-switch"
      [ngStyle]="{
        display: isRestrictedRoleSubmission ? 'none' : 'flex'
      }"
    >
      <button
        class="button-switch-set-left"
        [ngClass]="{
          'btn-active': headerV3Switch === 'left',
          'btn-inactive': headerV3Switch === 'right'
        }"
        (click)="headerSwitchClicked('left')"
      >
        <svg
          *ngIf="headerV3Switch === 'left'"
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
        >
          <circle
            cx="8"
            cy="8"
            r="6"
            fill="#FFAA00"
            stroke="#FFDD99"
            stroke-width="4"
          />
        </svg>
        {{ "HeaderV3.label.myQueue" | translate }}
      </button>
      <button
        class="button-switch-set-right"
        [ngClass]="{
          'btn-active': headerV3Switch === 'right',
          'btn-inactive': headerV3Switch === 'left'
        }"
        (click)="headerSwitchClicked('right')"
      >
        <svg
          *ngIf="headerV3Switch === 'right'"
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
        >
          <circle
            cx="8"
            cy="8"
            r="6"
            fill="#FFAA00"
            stroke="#FFDD99"
            stroke-width="4"
          />
        </svg>
        {{ "HeaderV3.label.teamsQueue" | translate }}
      </button>
    </div>
    <div class="button-header-v3">
      <button class="btn-common">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M21 21L17.5001 17.5M20 11.5C20 16.1944 16.1944 20 11.5 20C6.80558 20 3 16.1944 3 11.5C3 6.80558 6.80558 3 11.5 3C16.1944 3 20 6.80558 20 11.5Z"
            stroke="black"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </button>

      <button class="btn-common">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          [ngStyle]="{
            display: isRestrictedRoleSubmission ? 'none' : 'unset'
          }"
        >
          <path
            d="M11 3.99998H6.8C5.11984 3.99998 4.27976 3.99998 3.63803 4.32696C3.07354 4.61458 2.6146 5.07353 2.32698 5.63801C2 6.27975 2 7.11983 2 8.79998V17.2C2 18.8801 2 19.7202 2.32698 20.362C2.6146 20.9264 3.07354 21.3854 3.63803 21.673C4.27976 22 5.11984 22 6.8 22H15.2C16.8802 22 17.7202 22 18.362 21.673C18.9265 21.3854 19.3854 20.9264 19.673 20.362C20 19.7202 20 18.8801 20 17.2V13M7.99997 16H9.67452C10.1637 16 10.4083 16 10.6385 15.9447C10.8425 15.8957 11.0376 15.8149 11.2166 15.7053C11.4184 15.5816 11.5914 15.4086 11.9373 15.0627L21.5 5.49998C22.3284 4.67156 22.3284 3.32841 21.5 2.49998C20.6716 1.67156 19.3284 1.67155 18.5 2.49998L8.93723 12.0627C8.59133 12.4086 8.41838 12.5816 8.29469 12.7834C8.18504 12.9624 8.10423 13.1574 8.05523 13.3615C7.99997 13.5917 7.99997 13.8363 7.99997 14.3255V16Z"
            stroke="black"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </button>
      <button class="btn-common">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          [ngStyle]="{
            display: isRestrictedRoleSubmission ? 'none' : 'unset'
          }"
        >
          <path
            d="M21 21H4.6C4.03995 21 3.75992 21 3.54601 20.891C3.35785 20.7951 3.20487 20.6422 3.10899 20.454C3 20.2401 3 19.9601 3 19.4V3M21 7L15.5657 12.4343C15.3677 12.6323 15.2687 12.7313 15.1545 12.7684C15.0541 12.8011 14.9459 12.8011 14.8455 12.7684C14.7313 12.7313 14.6323 12.6323 14.4343 12.4343L12.5657 10.5657C12.3677 10.3677 12.2687 10.2687 12.1545 10.2316C12.0541 10.1989 11.9459 10.1989 11.8455 10.2316C11.7313 10.2687 11.6323 10.3677 11.4343 10.5657L7 15M21 7H17M21 7V11"
            stroke="black"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </button>
      <div class="avatar-profile">
        <a class="profile-link" (click)="handleProfileClick()">
          <ngx-avatars
            *ngIf="getName() !== ''"
            class="avt-profile"
            [textSizeRatio]="1.5"
            [name]="getName()"
          ></ngx-avatars>
        </a>
      </div>
    </div>
  </div>
  <ng-container
    *ngIf="alertV3.length > 0 && showHeaderV3"
    style="position: sticky"
  >
    <div class="alert-holder-v3">
      <div
        *ngFor="let alert of alertV3; let i = index"
        class="alert-wrapper"
        [ngStyle]="alert.wrapperStyle"
      >
        <boxxds-alert-v2
          [type]="alert.type"
          [linkData]="alert.linkData"
          [headerText]="alert.headerText | translate"
          [bodyText]="alert.bodyText | translate"
          (handleClick)="handleAlertLinkClickEvent($event, i)"
          (handleCloseEvent)="handleAlertClose(i)"
          [containerStyle]="{
            position: 'relative',
            top: 'unset',
            transition: 'all 0.3s ease-out',
            marginTop: '0'
          }"
        >
        </boxxds-alert-v2>
      </div>
    </div>
  </ng-container>
  <!-- new header component end -->
  <div
    class="content-holder"
    [ngClass]="{
      wf3: isWorkflow3 && !showHeaderV3,
      headerV3: showHeaderV3 && !isWorkflow3
    }"
  >
    <div
      class="content"
      [ngClass]="{ wf3: isWorkflow3, 'quotes-grid': isQuotesGridV2 }"
    >
      <router-outlet></router-outlet>
    </div>
  </div>
  <app-side-menu
    *ngIf="!isWorkflow3 && !showHeaderV3"
    [sideMenu]="menus"
    (toggleLogoutPopup)="toggleLogoutPopup()"
  >
  </app-side-menu>
  <app-side-menu-workflow3
    *ngIf="isWorkflow3 || showHeaderV3"
    [sideMenu]="menus"
    [boxxUser]="boxxUser"
    [showHeaderV3]="showHeaderV3"
    (toggleLogoutPopup)="toggleLogoutPopup()"
    (handleLogoutProceed)="logoutClickHandler()"
  >
  </app-side-menu-workflow3>
</div>
