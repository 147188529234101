import {
  Component,
  Input,
  OnInit,
  EventEmitter,
  Output,
  OnChanges,
  AfterViewInit,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BoxxUser } from 'src/app/entities/boxx-user';
import { NewQuoteService } from 'src/app/services/new-quote.service';
import { environment } from 'src/environments/environment';
import { QuoteWorkflow3Component } from '../../quote-workflow3.component';
import { QuotesGridV2Component } from '../quotes-grid-v2/quotes-grid-v2.component';
import { PolicyDirectoryComponent } from '../../policy-directory/policy-directory.component';
import { InsuredDirectoryWF3Component } from '../../insured/insured-directory/insured-directory.component';
import { InsuredWF3Component } from '../../insured/insured.component';
import { LocalStorageService } from 'src/app/services/localstorage-service';
import { Store } from '@ngrx/store';
import * as CreateQuoteAction from 'src/app/store/create-quote/create-quote.action';
import { BrokerageModuleComponent } from '../../brokerage-module/brokerage-module.component';

@Component({
  selector: 'app-side-menu-workflow3',
  templateUrl: './side-menu-workflow3.component.html',
  styleUrls: ['./side-menu-workflow3.component.less'],
})
export class SideMenuWorkflow3Component implements OnInit, OnChanges {
  @ViewChild('inputSearch') inputSearch: ElementRef<HTMLInputElement>;
  releaseVersion: string;
  isPopoverOpen = false;
  versionPopupContent: any;
  @Input() sideMenu: Array<any> = [
    {
      src: 'assets/img/sidemenu/profile.png',
      hoverSrc: 'assets/img/sidemenu/hover/profile.png',
      href: '/route',
      name: 'profile',
    },
  ];
  @Input() boxxUser: BoxxUser;
  @Input() showHeaderV3: boolean;

  @Output() handleLogoutProceed = new EventEmitter();

  isExpanded = false;
  form: FormGroup;
  menu;
  isAllMenuExpanded: boolean = false;
  menuIconSrc: string;
  logoutIconSrc: string;
  focusInput = false;
  showExitQuotingPopup = false;
  showLogoutPopup = false;
  routeList = {
    Quotes: [QuoteWorkflow3Component, QuotesGridV2Component],
    Policies: [PolicyDirectoryComponent, PolicyDirectoryComponent],
    Member: [
      InsuredDirectoryWF3Component,
      InsuredWF3Component,
      BrokerageModuleComponent,
    ],
  };
  navigationUrl = '';
  clearQuoteStore: boolean = false;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private newQuoteService: NewQuoteService,
    private localStorageService: LocalStorageService,
    private store: Store,
  ) {
    this.releaseVersion = environment.releaseVersion;
  }
  ngOnInit(): void {
    this.form = this.fb.group({
      search: [''],
    });
    const renderedComponent = this.activatedRoute?.children[0]?.component;
    this.menu = this.sideMenu.map((menu) => {
      let active = false;
      const currentComponent =
        this.activatedRoute?.children[0]?.firstChild?.component;
      if (
        currentComponent === QuoteWorkflow3Component &&
        (this.activatedRoute?.children[0]?.firstChild?.children[0].component ===
          InsuredWF3Component ||
          this.activatedRoute?.children[0]?.firstChild?.children[0]
            .component === BrokerageModuleComponent) &&
        menu?.name === 'Member'
      ) {
        return {
          ...menu,
          active: true,
          imgSrc: '',
        };
      } else if (
        (this.routeList[menu?.name]?.includes(currentComponent) &&
          this.activatedRoute?.children[0]?.firstChild?.children[0]
            .component !== InsuredWF3Component &&
          this.activatedRoute?.children[0]?.firstChild?.children[0]
            .component !== BrokerageModuleComponent) ||
        this.routeList[menu?.name]?.includes(renderedComponent)
      ) {
        active = true;
      }
      return {
        ...menu,
        active,
        imgSrc: '',
      };
    });
  }

  ngOnChanges() {
    const renderedComponent = this.activatedRoute?.children[0]?.component;
    this.menu = this.sideMenu.map((menu) => {
      let active = false;
      const currentComponent =
        this.activatedRoute?.children[0]?.firstChild?.component;
      if (
        currentComponent === QuoteWorkflow3Component &&
        (this.activatedRoute?.children[0]?.firstChild?.children[0].component ===
          InsuredWF3Component ||
          this.activatedRoute?.children[0]?.firstChild?.children[0]
            .component === BrokerageModuleComponent) &&
        menu?.name === 'Member'
      ) {
        return {
          ...menu,
          active: true,
          imgSrc: '',
        };
      } else if (
        (this.routeList[menu?.name]?.includes(currentComponent) &&
          this.activatedRoute?.children[0]?.firstChild?.children[0]
            .component !== InsuredWF3Component &&
          this.activatedRoute?.children[0]?.firstChild?.children[0]
            .component !== BrokerageModuleComponent) ||
        this.routeList[menu?.name]?.includes(renderedComponent)
      ) {
        active = true;
      }
      return {
        ...menu,
        active,
        imgSrc: '',
      };
    });
  }

  toggleSidebar(event) {
    event.stopPropagation();
    this.isExpanded = !this.isExpanded;
    this.inputSearch.nativeElement.focus();
    this.focusInput = false;
    this.resetActiveMenu();
  }

  clearValue() {
    this.menu = this.sideMenu.map((menu) => ({ ...menu }));
    this.isAllMenuExpanded = false;
  }

  openSidebar(event) {
    event.stopPropagation();
    this.isExpanded = true;
    this.focusInput = true;
    this.resetActiveMenu();
  }

  closeSidebar(event) {
    event.stopPropagation();
    this.isExpanded = false;
    this.focusInput = false;
    this.resetActiveMenu();
  }

  getName() {
    if (this.boxxUser?.firstName || this.boxxUser?.lastName) {
      return (
        (this.boxxUser?.firstName ?? '') + ' ' + (this.boxxUser?.lastName ?? '')
      );
    }
    return '';
  }

  toggleSubMenu(event, index) {
    if (this.isExpanded) {
      event.preventDefault();
      this.menu = this.menu.map((m, idx) => {
        if (idx !== index) {
          return { ...m, active: false };
        }
        return m;
      });
      this.menu[index].active = !this.menu[index].active;
      const collapsedMenus = this.menu.filter(
        (m) => m.submenu && m.submenu.length > 0 && m.active === false,
      );
      if (collapsedMenus.length <= 0) {
        this.isAllMenuExpanded = true;
      }
    } else {
      this.handleMenuNavigation(this.menu[index]);
    }
  }

  handleExpandOrCollapseAll() {
    const collapsedMenus = this.menu.filter(
      (m) => m.submenu && m.submenu.length > 0 && m.active === false,
    );
    if (collapsedMenus.length <= 0) {
      this.menu = this.menu.map((menu) => ({ ...menu, active: false }));
      this.isAllMenuExpanded = false;
    } else {
      this.menu = this.menu.map((menu) => ({ ...menu, active: true }));
      this.isAllMenuExpanded = true;
    }
  }

  onSearch(event) {
    if (!this.isExpanded) {
      this.isExpanded = !this.isExpanded;
    }
    if (event.target.value?.length === 0) {
      this.menu = this.sideMenu.map((menu) => ({ ...menu, active: false }));
      this.isAllMenuExpanded = false;
      return;
    }
    const searchKey = event.target.value.toLowerCase();
    let target = this.sideMenu.map((menu) => ({ ...menu, active: true }));
    let getFilteredMenus = target.filter((m) => this.filterMenu(m, searchKey));
    getFilteredMenus.map((m, i) => {
      if (m.submenu && m.submenu.length > 0) {
        getFilteredMenus[i].submenu = m.submenu.filter((submenu) =>
          submenu.name.toLowerCase().includes(searchKey),
        );
      }
    });
    this.menu = getFilteredMenus;
    const collapsedMenus = this.menu.filter(
      (m) => m.submenu && m.submenu.length > 0 && m.active === false,
    );
    this.isAllMenuExpanded = collapsedMenus.length <= 0;
  }

  filterMenu(target, key) {
    if (target.name.toLowerCase().includes(key)) {
      return true;
    } else if (target.submenu && target.submenu.length > 0) {
      if (
        target.submenu.filter((s) => s.name.toLowerCase().includes(key))
          .length > 0
      ) {
        return true;
      }
    }
    return false;
  }

  stopMenuToggle(event) {
    if (this.isExpanded) {
      event.stopPropagation();
    }
  }

  handleLogout(event) {
    event.stopPropagation();
  }

  handleShowLogoutPopup(event) {
    event.stopPropagation();
    this.showLogoutPopup = true;
  }

  handleOutsideClick() {
    this.form.reset();
    this.menu = this.sideMenu;
    this.isExpanded = false;
    this.focusInput = false;
    this.resetActiveMenu();
  }
  populateVersionContent() {
    this.versionPopupContent = 'versionPopupContent';
  }
  openPopover() {
    if (!this.isPopoverOpen) {
      this.populateVersionContent();
      this.isPopoverOpen = true;
    } else {
      this.isPopoverOpen = false;
    }
  }

  closePopover(event) {
    event.stopPropagation();
    this.isPopoverOpen = false;
  }

  handleOutsideNavigationClick(forcePageReload = false) {
    const rootComponentName = this.findRootComponent(this.activatedRoute);
    if (rootComponentName == 'BrokerageDirectoryWorkflow3Component') {
      this.showExitQuotingPopup = false;
      this.showLogoutPopup = false;
      // this.navigationUrl = '';
      this.newQuoteService.clearSideMenu();
    }

    if (
      this.activatedRoute?.children[0]?.firstChild?.component ===
        QuoteWorkflow3Component &&
      this.activatedRoute?.children[0]?.firstChild?.children[0].component !==
        InsuredWF3Component &&
      this.activatedRoute?.children[0]?.firstChild?.children[0].component !==
        BrokerageModuleComponent
    ) {
      this.showExitQuotingPopup = true;
    } else {
      if (this.clearQuoteStore) {
        this.store.dispatch(new CreateQuoteAction.ResetState());
        this.newQuoteService.clearInsuredId();
      }

      if (forcePageReload) {
        let isMock = 'true';
        if (
          this.localStorageService.getMockExternalAPIs() === 'false' ||
          environment.mockExternalAPIs === false
        ) {
          isMock = 'false';
        }
        this.router
          .navigate(['/dashboard'], {
            queryParams: {
              mock: isMock,
            },
            skipLocationChange: true,
          })
          .then(() => {
            // to trigger the page reload
            this.router.navigate([this.navigationUrl], {
              queryParams: {
                mock: isMock,
              },
              skipLocationChange: true,
            });
          });
      } else {
        this.router.navigateByUrl(this.navigationUrl, {
          skipLocationChange: true,
        });
      }
    }
  }

  findRootComponent(route: ActivatedRoute): string {
    let currentRoute = route;
    while (currentRoute.firstChild) {
      currentRoute = currentRoute.firstChild;
    }
    const rootComponentName = currentRoute.component?.name;
    return rootComponentName || '';
  }

  handleProfileClick() {
    this.navigationUrl = '/dashboard/home';
    this.handleOutsideNavigationClick();
  }

  handleExitQuotingProcess() {
    if (this.clearQuoteStore === true) {
      this.store.dispatch(new CreateQuoteAction.ResetState());
      this.newQuoteService.clearInsuredId();
    }
    this.router.navigateByUrl(this.navigationUrl, { skipLocationChange: true });
  }

  handleCancelExiting() {
    this.showExitQuotingPopup = false;
    this.showLogoutPopup = false;
    this.navigationUrl = '';
    this.newQuoteService.clearSideMenu();
  }

  handleLogoutClick() {
    this.handleLogoutProceed.emit();
  }

  handleSubMenuNavigation(submenu, menu) {
    if (submenu?.disabled) {
      return;
    }
    if (submenu.name === 'Start new quote' || 'Start new submission') {
      this.clearQuoteStore = true;
    }
    if (menu?.name === 'Quotes' || menu?.name === 'Policies') {
      this.newQuoteService.setSideMenu(submenu?.name);
    } else {
      this.newQuoteService.clearSideMenu();
    }
    this.navigationUrl = submenu.href;
    if (
      menu?.name === 'Quotes' &&
      this.routeList[menu?.name]?.includes(QuotesGridV2Component)
    ) {
      this.handleOutsideNavigationClick(true);
    } else if (
      menu?.name === 'Policies' &&
      this.routeList[menu?.name]?.includes(PolicyDirectoryComponent)
    ) {
      this.handleOutsideNavigationClick(true);
    } else {
      this.handleOutsideNavigationClick();
    }
  }

  handleMenuNavigation(menu) {
    this.navigationUrl = menu.href;
    this.handleOutsideNavigationClick();
  }

  resetActiveMenu() {
    const renderedComponent = this.activatedRoute?.children[0]?.component;
    this.menu = this.sideMenu.map((menu) => {
      let active = false;
      const currentComponent =
        this.activatedRoute?.children[0]?.firstChild?.component;
      if (
        currentComponent === QuoteWorkflow3Component &&
        (this.activatedRoute?.children[0]?.firstChild?.children[0].component ===
          InsuredWF3Component ||
          this.activatedRoute?.children[0]?.firstChild?.children[0]
            .component === BrokerageModuleComponent) &&
        menu?.name === 'Member'
      ) {
        return {
          ...menu,
          active: true,
          imgSrc: '',
        };
      } else if (
        (this.routeList[menu?.name]?.includes(currentComponent) &&
          this.activatedRoute?.children[0]?.firstChild?.children[0]
            .component !== InsuredWF3Component &&
          this.activatedRoute?.children[0]?.firstChild?.children[0]
            .component !== BrokerageModuleComponent) ||
        this.routeList[menu?.name]?.includes(renderedComponent)
      ) {
        active = true;
      }
      return {
        ...menu,
        active,
        imgSrc: '',
      };
    });
  }
}
