export const CYBER_COMMERCIAL_PRODUCT_NAME = 'cybercommercial';
export const CYBER_EXCESS_PRODUCT_NAME = 'cyberexcess';
export const CYBER_EXCESS_RISK_ID = 3;
export const STANDALONE_SELLABLE_TRUE = 1;
export const DOCUMENTTYPE = 'DOCUMENTTYPE';
export const DOCUMENTTYPE_ENDORSEMENT = 'DOCUMENTTYPE_ENDORSEMENT';
export const DOCUMENTTYPE_POLICY = 'DOCUMENTTYPE_POLICY';
export const TEMPLATETYPE = 'TEMPLATETYPE';
export const TEMPLATETYPE_ENDORSEMENT = 'TEMPLATETYPE_ENDORSEMENT';
export const TEMPLATETYPE_POLICYWORDING = 'TEMPLATETYPE_POLICYWORDING';
export const BROKER_COMMISSION_STEP_VALUE = 0.5;
export const DOCUMENTTYPE_DECLARATION = 'DOCUMENTTYPE_DECLARATION';
export const TEMPLATETYPE_DECLARATION = 'TEMPLATETYPE_DECLARATION';
export const REFERRAL_ERROR_CODE = -20;
export const INSURED_COMPANY_TYPE = 2;
export const GENERATE_DOC_SUCCESS_STATUS_CODE = 1;
export const GENERATE_DOC_ERROR_STATUS_CODE = 2;
export const DOMAIN_CODE_RANGENUMHOUSEMEMBERS = 'RANGENUMHOUSEMEMBERS';
export const DOMAIN_CODE_RANGENUMEMPLOYEES = 'RANGENUMEMPLOYEES';
export const DOMAIN_CODE_PENDINGINFOREASON = 'PENDINGINFOREASON';
export const DOMAIN_CODE_QUOTEFLOWSTATUS = 'QUOTEFLOWSTATUS';
export const DOMAIN_CODE_REASONTYPE = 'REASONTYPE';
export const SUB_DOMAIN_CODE_QUOTEFLOWSTATUS_PENDINGINFO =
  'QUOTEFLOWSTATUS_PENDINGINFO';
export const SUB_DOMAIN_CODE_REASONTYPE_PENDINGINFO = 'REASONTYPE_PENDINGINFO';
export const FINANCIAL_COVERAGE = 'financial crime and fraud';
export const FINANCIAL_COVERAGE_LIMIT = 25000;
export const FINANCIAL_COVERAGE_RULE_MIN_DEDUCTIBLE = 10000;
export const EFFECTIVE_DATE_MAX_RANGE_LIMIT = 60;
export const COVERAGE_SUBLIMIT_B10 = 'B.10 KEY PERSON COVER';
export const DOC_GENERATION_WAIT_TIME = 90 * 1000;
export const UNDERWRITER = 'UNDERWRITER';
export const DOMAIN_CODE_QUOTESTATUS_QUOTED = 152;
export const DOMAIN_CODE_QUOTESTATUS_INDICATED = 333;
export const ALLOWED_ENDORSEMENTS_COUNT = 6;
export const DOC_GENERATION_STATUS_SUCCESS = 'success';
export const DOC_GENERATION_STATUS_ERROR = 'error';
export const DOC_GENERATION_STATUS_IN_PROGRESS = 'in progress';
export const DOCMERGESTAGE_ENDORSEMENT = 'DOCMERGESTAGE_ENDORSEMENT';
export const DISPLAYTEXT_QUOTESUBMISSION_WARNING = 'Warning';
export const DISPLAYTEXT_WARNING_LONDONREFERRAL = 'London Referral';
export const DISPLAYTEXT_QUOTEFLOWSTATUS_PREQUOTE = 'Pre-quote';
export const DISPLAYTEXT_QUOTEFLOWSTATUS_PENDINGINFO = 'Pending info';
export const DISPLAYTEXT_QUOTEFLOWSTATUS_TOREVIEW = 'To Review';
export const DISPLAYTEXT_QUOTEFLOWSTATUS_APPROVED = 'Approved';
export const SUBDOMAINCODE_QUOTEFLOWSTATUS_PREQUOTE =
  'QUOTEFLOWSTATUS_PREQUOTE';
export const SUBDOMAINCODE_QUOTEFLOWSTATUS_PENDINGINFO =
  'QUOTEFLOWSTATUS_PENDINGINFO';
export const SUBDOMAINCODE_QUOTEFLOWSTATUS_TOREVIEW =
  'QUOTEFLOWSTATUS_TOREVIEW';
export const SUBDOMAINCODE_QUOTEFLOWSTATUS_APPROVED =
  'QUOTEFLOWSTATUS_APPROVED';
