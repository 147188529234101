import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { take } from 'rxjs';
import { Pagination } from 'src/app/entities/boxx-response';
import { TooltipV2Model } from 'src/app/models/tooltip.V2.model ';
import { getCurrencySelector } from 'src/app/store/dashboard/dashboard.selector';
import { FormBuilder, FormGroup } from '@angular/forms';
import { firstValueFrom } from 'rxjs';
import {
  DOMAIN_CODE_PENDINGINFOREASON,
  DOMAIN_CODE_QUOTEFLOWSTATUS,
  SUB_DOMAIN_CODE_QUOTEFLOWSTATUS_PENDINGINFO,
} from 'src/app/constants/quote-constant';
import {
  QUOTEFLOWSTATUS_PREQUOTE,
  QUOTEFLOWSTATUS_TOREVIEW,
  QuoteStatus,
} from 'src/app/constants/quoteStatus';
import { DropdownListDto } from 'src/app/dtos/dropdownList.dto';
import { DomainsService } from 'src/app/services/domains.service';
import { PolicyRiskTrxQuoteFlowStatusService } from 'src/app/services/policy-risk-trx-quote-flow-status.service';
import { SharedFunctionService } from 'src/app/services/shared/shared-function.service';
import { getErrorMessage } from 'src/app/utils/utils';
import { PolicyPeriodService } from 'src/app/services/policy-period.service';
import { NewQuoteService } from 'src/app/services/new-quote.service';

@Component({
  selector: 'app-scroll-pagination-table',
  templateUrl: './scroll-pagination-table.component.html',
  styleUrls: ['./scroll-pagination-table.component.less'],
})
export class ScrollPaginationTableComponent
  implements OnInit, OnChanges, OnDestroy
{
  @Input() tableData: { [key: string]: any }[] = [];
  @Input() tableColumns: string[] = [];
  @Input() tableColumnsExcluded: string[] = [];
  @Input() noDataMessage: string = 'No data available.';
  @Input() isLoading: boolean = true;
  @Input() pagination: Pagination;
  @Input() filterTab: number;
  @Input() quotesData;
  @Input() isRestrictedRoleSubmission: boolean = false;

  @Output() getTableDataOnSort = new EventEmitter();
  @Output() getTableDataOnScroll = new EventEmitter();
  @Output() getTableDataOnRowClick = new EventEmitter();
  @Output() handleStatusChanged = new EventEmitter();

  isExpanded: boolean = false;
  currentSortColumn: string = '';
  sortOrder: string = '';
  isDefaultHeight: boolean = true;
  container: HTMLElement | null = null;
  currency: string = '';

  currentPage: number = 1;
  // totalPages: number = 1;
  buffer: number = 150;
  previousScrollTop: number = 0;

  initialToggle: boolean = false;
  isScrollListenerAdded = false;
  statusChangePopupIdx = null;
  pendingInfoReasons: DropdownListDto[];
  form: FormGroup;
  buttonDisabled: boolean = true;
  selectedReasonId: number = 0;
  selectedReasonDesc: string = '';
  quoteFlowStatus;
  showStatusChangedBadge: boolean = false;
  statusChangedBadgeIndex = null;
  statusChangePositionalStyles = {};

  constructor(
    private store: Store,
    private domainsService: DomainsService,
    private fb: FormBuilder,
    private policyRiskTrxQuoteFlowStatusService: PolicyRiskTrxQuoteFlowStatusService,
    private sharedFunctionService: SharedFunctionService,
    private policyPeriodService: PolicyPeriodService,
    private newQuoteService: NewQuoteService,
  ) {
    this.form = this.fb.group({
      reason: [''],
    });
  }

  async ngOnInit(): Promise<void> {
    this.handleAddScrollListener();
    this.store
      .select(getCurrencySelector)
      .pipe(take(1))
      .subscribe((value) => (this.currency = value));

    await this.getPendingInfoReasons();
    await this.getQuoteFlowStatus();
  }

  ngOnChanges(changes: SimpleChanges): void {
    !this.isLoading && this.handleAddScrollListener();
    if (changes['filterTab']) {
      if (
        changes['filterTab'].currentValue !== changes['filterTab'].previousValue
      ) {
        this.currentSortColumn = '';
        this.sortOrder = '';
      }
    }
  }

  ngOnDestroy(): void {
    this.handleRemoveScrollListener();
  }

  handleScroll = () => {
    if (!this.container) return;

    this.statusChangePopupIdx = null;

    const scrollTop = this.container.scrollTop;
    const containerHeight = this.container.clientHeight;
    const scrollHeight = this.container.scrollHeight;

    const isScrollingDown = scrollTop > this.previousScrollTop;
    const isScrollingUp = scrollTop < this.previousScrollTop;

    this.previousScrollTop = scrollTop;

    if (
      isScrollingDown &&
      scrollTop > 0 &&
      scrollHeight - scrollTop - containerHeight < this.buffer &&
      this.currentPage < this.pagination.totalPages
    ) {
      this.handleRemoveScrollListener();
      this.currentPage++;
      this.handleTableData(this.currentPage, 'scrollingDown');
    }

    // else if (
    //   isScrollingUp &&
    //   scrollTop > 0 &&
    //   scrollTop < this.buffer &&
    //   this.currentPage > 1
    // ) {
    //   this.currentPage--;
    //   this.handleTableData(this.currentPage, 'scrollingUp');
    // }
  };

  async handleTableData(page: number, scrollDirection: string) {
    let filter = {
      page: this.currentPage,
      scrollDirection,
    };
    this.currentSortColumn !== ''
      ? (filter['sort'] = `${this.currentSortColumn}:${this.sortOrder}`)
      : delete filter['sort'],
      this.getTableDataOnScroll.emit(filter);
  }

  async handleSort(tableColumn: string): Promise<void> {
    if (tableColumn === this.currentSortColumn) {
      this.sortOrder = this.sortOrder === 'asc' ? 'desc' : 'asc';
    } else {
      this.currentSortColumn = tableColumn;
      this.sortOrder = 'asc';
    }
    this.getTableDataOnSort.emit({
      sortColumn: this.currentSortColumn,
      sortOrder: this.sortOrder,
      page: this.currentPage,
    });
  }

  handleTableCollapseAndExpand(): void {
    this.isExpanded = !this.isExpanded;
    this.isDefaultHeight = false;
    if (!this.initialToggle) {
      this.initialToggle = true;
      this.currentPage++;
      this.handleTableData(this.currentPage, 'scrollingDown');
    }
  }

  setupTooltipBranch(rowData): TooltipV2Model {
    if (rowData?.Broker?.branch)
      return {
        type: 'list',
        hiddenHead: false,
        hiddenHeadTxt: true,
        tooltipHeaderTxt: '',
        tooltipContentTxt: '',
        tooltipContentList: [rowData?.Broker.branch],
      };
    else
      return {
        type: '',
        hiddenHead: false,
        hiddenHeadTxt: true,
        tooltipHeaderTxt: '',
        tooltipContentTxt: '',
        tooltipContentList: [],
      };
  }

  handleRemoveScrollListener() {
    if (this.container && this.isScrollListenerAdded) {
      this.container.removeEventListener('scroll', this.handleScroll);
      this.isScrollListenerAdded = false;
    }
  }
  handleAddScrollListener() {
    if (!this.container) {
      this.container = document.getElementById(
        'scrollable-pagination-table-wrapper',
      );
    }

    if (this.container && !this.isScrollListenerAdded) {
      this.container.addEventListener('scroll', this.handleScroll);
      this.isScrollListenerAdded = true;
    }
  }

  async handleRowClick(
    rowData: { [key: string]: any },
    column: string,
    rowIndex: number,
  ) {
    if (this.isRestrictedRoleSubmission) {
      return;
    }
    // make isRead true
    const updateReadFlagPayload = {
      isRead: true,
    };

    const quoteData = this.quotesData[rowIndex];

    this.newQuoteService.updateQuoteFlowStatusData({
      Status:
        quoteData?.policyRiskTrxes?.quoteProcessStatus?.subdomaincode ?? '',
      toReviewReferredBy: this.getReferredBy(quoteData) ?? '',
      pendingInfoReason: this.getPendingInfoResons(quoteData) ?? [],
    });

    const policyPeriodId = quoteData.policyPeriod.id;
    const updatePolicyPeriodAPI = this.policyPeriodService.Update(
      policyPeriodId,
      updateReadFlagPayload,
    );
    const updateReadFlagResponse = await firstValueFrom(updatePolicyPeriodAPI);

    this.getTableDataOnRowClick.emit({ rowData, column });
  }

  async getPendingInfoReasons() {
    const reasons = await this.domainsService.GetDomainListAsync(
      DOMAIN_CODE_PENDINGINFOREASON,
    );
    this.pendingInfoReasons = reasons.map((data) => {
      return { key: data.value, value: data.id.toString(), id: data.id };
    });
  }

  async getQuoteFlowStatus() {
    const getDomainsAPI = this.domainsService.GetByDomainCode(
      DOMAIN_CODE_QUOTEFLOWSTATUS,
    );
    const quoteFlowStatusResponse = await firstValueFrom(getDomainsAPI);
    this.quoteFlowStatus = quoteFlowStatusResponse?.data;
  }

  handleStatusClick(event, index) {
    event.stopPropagation();
    if (this.isRestrictedRoleSubmission) {
      return;
    }
    const quoteStatus =
      this.quotesData[index]?.policyRiskTrxes?.quoteStatus.description;
    const quoteFlowStatus =
      this.quotesData[index]?.policyRiskTrxes?.quoteProcessStatus?.description;
    if (
      quoteStatus?.toLowerCase() === QuoteStatus['Submission']?.toLowerCase() &&
      (quoteFlowStatus?.toLowerCase() ===
        QUOTEFLOWSTATUS_PREQUOTE?.toLowerCase() ||
        quoteFlowStatus?.toLowerCase() ===
          QUOTEFLOWSTATUS_TOREVIEW?.toLowerCase())
    ) {
      this.statusChangePopupIdx = index;
      // to position status change popup
      if (event.pageY > 500) {
        this.statusChangePositionalStyles = {
          top: '-200px',
        };
      } else {
        this.statusChangePositionalStyles = {
          top: '0px',
        };
      }
    }
  }

  closeStatusChangePopup() {
    this.statusChangePopupIdx = null;
  }

  reasonHandler(value: number) {
    this.selectedReasonId = value;
    this.selectedReasonDesc =
      this.pendingInfoReasons.find((a) => a.id === value)?.key || '';
    this.buttonDisabled = !this.isValidSelection(value);
  }

  private isValidSelection(value: number): boolean {
    return value && value > 0;
  }

  async handleStatusChange(rowIndex) {
    const quoteFlowStatusCode = this.quoteFlowStatus.filter(
      (status) =>
        status.subdomaincode === SUB_DOMAIN_CODE_QUOTEFLOWSTATUS_PENDINGINFO,
    )[0]?.id;
    const policyRiskTrxId = this.quotesData[rowIndex]?.policyRiskTrxes?.id;
    if (!!quoteFlowStatusCode && !!this.selectedReasonId) {
      const payload = {
        quoteFlowStatus: quoteFlowStatusCode,
        reason: this.selectedReasonId,
      };
      try {
        const updateQuoteFlowStatusAPI =
          this.policyRiskTrxQuoteFlowStatusService.UpdateQuoteFlowStatus(
            policyRiskTrxId,
            payload,
          );
        const updateResponse = await firstValueFrom(updateQuoteFlowStatusAPI);
        this.showStatusChangedBadge = true;
        this.statusChangedBadgeIndex = rowIndex;
        this.handleStatusChanged.emit({
          rowIndex,
          selectedReasonDesc: this.selectedReasonDesc,
        });
        if (
          this.selectedReasonDesc?.length > 0 &&
          this.tableData[rowIndex]?.['pendingInfoReason'].length === 0
        )
          this.tableData[rowIndex]?.['pendingInfoReason'].push(
            this.selectedReasonDesc,
          );
        setTimeout(() => {
          this.showStatusChangedBadge = false;
        }, 5000);
        this.statusChangePopupIdx = null;
      } catch (error) {
        this.statusChangePopupIdx = null;
        this.sharedFunctionService.triggerEvent({
          type: 'error',
          headerText: 'error',
          bodyText: getErrorMessage(error),
        });
      }
    }
  }

  getReferredBy(quoteData: any): string {
    return quoteData?.policyPeriod?.refferedBy
      ? quoteData?.policyPeriod?.refferedBy.firstName +
          ' ' +
          quoteData?.policyPeriod?.refferedBy.lastName
      : '';
  }
  getPendingInfoResons(quoteData: any): string[] {
    return quoteData?.policyRiskTrxes?.reason?.split(',') || [];
  }
}
