<div class="dashboard-home-container">
  <div class="main-table-container">
    <div class="main-table-filter-head-container">
      <section class="tabs">
        <div class="tab" *ngFor="let tab of filterTabs">
          <button
            [ngClass]="{ 'btn-active': tab.active }"
            [class.noPointerEvents]="tab.active || showTaskTblSpinner"
            (click)="!tab.active && !showTaskTblSpinner && handleFilterTab(tab)"
          >
            <p class="btn-text">{{ tab.name }}</p>
            <p
              class="filter-count"
              *ngIf="!showTaskTblSpinner"
              [ngClass]="{ 'has-unread': tab.hasUnread }"
            >
              <span class="unread-indicator" *ngIf="tab.hasUnread"></span>
              {{ tab.count }}
            </p>
            <p
              class="filter-count-loading-style"
              *ngIf="showTaskTblSpinner"
            ></p>
          </button>
        </div>
      </section>
      <section class="actions">
        <button
          class="action-btn search"
          *ngIf="!isSearchable"
          (click)="isSearchable = true"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
          >
            <path
              d="M17.5 17.5L13.875 13.875M15.8333 9.16667C15.8333 12.8486 12.8486 15.8333 9.16667 15.8333C5.48477 15.8333 2.5 12.8486 2.5 9.16667C2.5 5.48477 5.48477 2.5 9.16667 2.5C12.8486 2.5 15.8333 5.48477 15.8333 9.16667Z"
              stroke="#98A2B3"
              stroke-width="1.66667"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </button>
        <boxxds-textinput-v2
          *ngIf="isSearchable"
          [form]="filterForm"
          [placeholder]="
            ('workFlow3.components.shared.placeholder.search' | translate) +
            ' name'
          "
          [control]="'insuredName'"
          [id]="'search'"
          [leftIcon]="'assets/img/workflow3/sidemenu/search.png'"
          [width]="'200px'"
          [maxLength]="'40'"
          (handleKeyUp)="onInsuredSearch($event)"
          [inputStyle]="{ marginTop: '0' }"
          [leftIconStyle]="{ transform: 'translateY(-50%)' }"
        >
        </boxxds-textinput-v2>
        <button class="action-btn filter">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
          >
            <path
              d="M2.82125 4.72239C2.19097 4.01796 1.87583 3.66574 1.86394 3.3664C1.85361 3.10636 1.96536 2.85643 2.16603 2.69074C2.39704 2.5 2.86966 2.5 3.81491 2.5H16.184C17.1293 2.5 17.6019 2.5 17.8329 2.69074C18.0336 2.85643 18.1453 3.10636 18.135 3.3664C18.1231 3.66574 17.808 4.01796 17.1777 4.72239L12.4225 10.037C12.2968 10.1774 12.234 10.2477 12.1892 10.3276C12.1495 10.3984 12.1203 10.4747 12.1027 10.554C12.0828 10.6435 12.0828 10.7377 12.0828 10.9261V15.382C12.0828 15.5449 12.0828 15.6264 12.0565 15.6969C12.0333 15.7591 11.9955 15.8149 11.9463 15.8596C11.8907 15.9102 11.815 15.9404 11.6637 16.001L8.83039 17.1343C8.5241 17.2568 8.37096 17.3181 8.24802 17.2925C8.14052 17.2702 8.04617 17.2063 7.98551 17.1148C7.91613 17.0101 7.91613 16.8452 7.91613 16.5153V10.9261C7.91613 10.7377 7.91613 10.6435 7.89623 10.554C7.87858 10.4747 7.84943 10.3984 7.8097 10.3276C7.76491 10.2477 7.70209 10.1774 7.57645 10.037L2.82125 4.72239Z"
              stroke="#98A2B3"
              stroke-width="1.66667"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </button>
        <button class="action-btn export">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
          >
            <path
              d="M17.5 12.5V13.5C17.5 14.9001 17.5 15.6002 17.2275 16.135C16.9878 16.6054 16.6054 16.9878 16.135 17.2275C15.6002 17.5 14.9001 17.5 13.5 17.5H6.5C5.09987 17.5 4.3998 17.5 3.86502 17.2275C3.39462 16.9878 3.01217 16.6054 2.77248 16.135C2.5 15.6002 2.5 14.9001 2.5 13.5V12.5M14.1667 8.33333L10 12.5M10 12.5L5.83333 8.33333M10 12.5V2.5"
              stroke="#98A2B3"
              stroke-width="1.66667"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </button>
      </section>
    </div>
    <app-scroll-pagination-table
      [ngStyle]="{ width: '100%' }"
      [tableData]="taskTableData"
      [pagination]="taskPagination"
      [noDataMessage]="'Your task list is empty.'"
      [tableColumns]="taskTableHeaders"
      [tableColumnsExcluded]="tableColumnsExcluded"
      (getTableDataOnSort)="getTaskTableDataOnSort($event)"
      (getTableDataOnScroll)="getTaskTableDataOnScroll($event)"
      (getTableDataOnRowClick)="getTableDataOnRowClick($event)"
      [isLoading]="showTaskTblSpinner"
      [filterTab]="currentFilterTabIndex"
      [quotesData]="unFilteredQuotesData"
      [isRestrictedRoleSubmission]="isRestrictedRoleSubmission"
      (handleStatusChanged)="handleStatusChanged($event)"
    ></app-scroll-pagination-table>
  </div>
</div>
