import { Component, Input, OnInit, ElementRef } from '@angular/core';
import { TooltipV2Model } from 'src/app/models/tooltip.V2.model ';

@Component({
  selector: 'app-info-tooltip-v2',
  templateUrl: './info-tooltip-v2.component.html',
  styleUrls: ['./info-tooltip-v2.component.less'],
})
export class InfoTooltipV2Component implements OnInit {
  @Input() tooltipObject: TooltipV2Model = {
    type: '',
    hiddenHead: false,
    hiddenHeadTxt: true,
    tooltipHeaderTxt: '',
    tooltipContentTxt: '',
    tooltipContentList: [],
  };

  tooltipShow: boolean = false;
  isTopPosition: boolean = false;
  position = { top: '0px', left: '0px' };

  constructor(private el: ElementRef) {}

  ngOnInit(): void {}

  showTooltip(event: MouseEvent): void {
    // this.tooltipShow = false;
    const screenHeight = window.innerHeight;
    const sectionHeight = screenHeight / 3; // Divide the screen into three equal sections
    const offsetY = 10; // Offset to prevent overlap with the cursor
    let approxHeightToReduce = 30;
    if (this.tooltipObject?.tooltipContentList?.length > 2)
      approxHeightToReduce = 60;
    else approxHeightToReduce = 1;

    // Determine the tooltip position based on the Y-coordinate
    if (event.clientY <= sectionHeight) {
      // Mouse is in the top third of the screen: show tooltip below
      this.isTopPosition = false;
      this.position = {
        top: `${event.clientY + offsetY}px`,
        left: `${event.clientX + offsetY}px`,
      };
    } else if (event.clientY >= 2 * sectionHeight) {
      // Mouse is in the bottom third of the screen: show tooltip above
      this.isTopPosition = true;
      this.position = {
        top: `${event.clientY - approxHeightToReduce - offsetY}px`,
        left: `${event.clientX + offsetY}px`,
      };
    } else {
      // Mouse is in the middle third of the screen: show tooltip below
      this.isTopPosition = false;
      this.position = {
        top: `${event.clientY + offsetY}px`,
        left: `${event.clientX + offsetY}px`,
      };
    }
    this.tooltipShow = true;
  }

  hideTooltip(): void {
    this.tooltipShow = false;
  }
}
